import { useEffect, useState } from "react";
import { deleteData, getData } from "../../webService/webService";
import {
  Button,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Card,
  TableHead,
  Box,
  Tooltip,
  Avatar,
  Badge,
} from "@mui/material";
import Scrollbar from "../../components/scrollbar";
import { UserListHead } from "../../sections/@dashboard/user";
import Iconify from "../../components/iconify";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { showConfirmationDialog } from "../../utils/sweetalertUtils";
// import TEAMROUTES from "../../API_Routes/Team/Team_Routers";
import CreateTeam from "./createTeam";
import TEAMROUTES from "../../API_Routes/Team/Team_Routers";
import LOCATIONROUTES from "../../API_Routes/Location/Location_Routers";
import { useDispatch } from "react-redux";
import PaginationComponent from "../../components/Pagination";

const AllTeam = () => {
  // Table
  const dispatch = useDispatch();
  const accessToken = sessionStorage.getItem("Token");
  const [open, setOpen] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [USERLIST, setUSERLIST] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalcount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(5);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    fetchdata(0, event.target.value);
    setCurrentPage(1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage <= totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedUserId(id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const fetchdata = (skip, limit) => {
    const params = {
      skip: skip,
      limit: limit,
    };
    getData(TEAMROUTES.GETTEAM, params, accessToken)
      .then((res) => {
        console.log(res);
        setUSERLIST(res.data);
        setTotalPages(res.totalPages);
        setTotalCount(res.totalCounts);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const skip = (currentPage - 1) * limit;
    fetchdata(skip, limit);
  }, [currentPage]);

  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [viewMode, setViewMode] = useState(false);

  let EditUserData;
  const Handleshow = (data) => {
    EditUserData = USERLIST.find((item) => item._id === selectedUserId);
    const extractIds = (obj) => {
      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          obj[key] = obj[key].map((item) => item._id);
        }
      }
      return obj;
    };
    extractIds(EditUserData);
    if (data === "View") {
      setViewMode(true);
    }
    setShowEdit(true);
    setEditData(EditUserData);
  };

  const handleCloseEdit = () => {
    setShowEdit(false);
    setViewMode(false);
    setEditData(null);
    setOpen(null);
    const skip = (currentPage - 1) * limit;
    fetchdata(skip, limit);
  };

  const DeleteId = async (val) => {
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      deleteData(TEAMROUTES.DELETETEAM(val), accessToken, dispatch)
        .then((res) => {
          setOpen(null);
          const skip = (currentPage - 1) * limit;
          fetchdata(skip, limit);
        })
        .catch((error) => {
          setOpen(null);
          console.error("Error:", error);
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const getRoleBasedColor = (role) => {
    switch (role) {
      case "Regional Head":
        return "#cab8ff";
      case "Relation Executive":
        return "#b5deff";
      case "Field Officer":
        return "#c1ffd7";
      case "Area Sales Manager":
        return "#81f5ff";
      default:
        return "#cab8ff";
    }
  };

  const renderTeamIcons = (teamMembers, role) => {
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {teamMembers.slice(0, 3).map((member, index) => (
          <Tooltip key={index} title={`${member?.firstName}`}>
            <Avatar
              sx={{
                width: 25,
                height: 25,
                mr: 1,
                backgroundColor: getRoleBasedColor(member?.role),
                fontSize: "13px",
              }}
              className="cursor-pointer text-black"
            >
              {member?.firstName?.charAt(0)}
            </Avatar>
          </Tooltip>
        ))}
        {teamMembers.length > 3 && (
          <Badge
            badgeContent={`+${teamMembers.length - 3}`}
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: "black",
                color: "white",
              },
            }}
            className="cursor-pointer text-black"
          >
            <Avatar
              sx={{
                width: 25,
                height: 25,
                backgroundColor: getRoleBasedColor(role),
                fontSize: "13px",
              }}
              className="text-black"
            >
              {role?.charAt(0)}
            </Avatar>
          </Badge>
        )}
      </Box>
    );
  };

  // Fetch all locations
  const [allZone, setZone] = useState();
  const fetchZone = () => {
    getData(LOCATIONROUTES.GETLOCATION, null, accessToken)
      .then((res) => {
        setZone(res.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchZone();
  }, []);

  // Function to find district and zoneName by id
  const findDistrictAndZone = (id) => {
    const result = allZone.find((item) => item._id === id);
    if (result) {
      return `${result.district} - ${result.zoneName}`;
    } else {
      return "Not Found";
    }
  };

  const calculateIndex = (index) => {
    return (currentPage - 1) * limit + index + 1;
  };

  return (
    <>
      <Helmet>
        <title>All Team</title>
      </Helmet>

      <ToastContainer />
      {showEdit ? (
        <>
          <CreateTeam
            editData={editData}
            handleCloseEdit={handleCloseEdit}
            selectedUserId={selectedUserId}
            viewMode={viewMode}
          />
        </>
      ) : (
        <>
          <Container>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Typography variant="h4" gutterBottom>
                All Teams
              </Typography>
            </Stack>

            <Card>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }} className="h-350">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No</TableCell>
                        <TableCell>Zone</TableCell>
                        <TableCell align="center">Regional Head</TableCell>
                        <TableCell align="center">Area Sales Manager</TableCell>
                        <TableCell align="center">Relation Executive</TableCell>
                        <TableCell align="center">Field Officer</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {USERLIST?.map((row, index) => {
                        const {
                          _id,
                          zoneId,
                          areaSalesManagerId,
                          fieldOfficerId,
                          regionalHeadId,
                          relationExecutiveId,
                        } = row;

                        return (
                          <TableRow key={_id}>
                            <TableCell>{calculateIndex(index)}</TableCell>
                            <TableCell>{findDistrictAndZone(zoneId)}</TableCell>

                            {/* Regional Head */}
                            <TableCell align="center">
                              {regionalHeadId
                                .map((data) => data.firstName)
                                .join(", ")}

                              {/* {renderTeamIcons(regionalHeadId, "Regional Head")} */}
                            </TableCell>

                            {/* Area Sales Manager */}
                            <TableCell align="center">
                              {areaSalesManagerId
                                .map((data) => data.firstName)
                                .join(", ")}
                              {/* {renderTeamIcons(
                                areaSalesManagerId,
                                "Area Sales Manager"
                              )} */}
                            </TableCell>

                            {/* Relation Executive */}
                            <TableCell align="center">
                              {renderTeamIcons(
                                relationExecutiveId,
                                "Relation Executive"
                              )}
                            </TableCell>

                            {/* Field Officer */}
                            <TableCell align="center">
                              {renderTeamIcons(fieldOfficerId, "Field Officer")}
                            </TableCell>

                            <TableCell align="left">
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(event) => handleOpenMenu(event, _id)}
                              >
                                <Iconify icon={"eva:more-vertical-fill"} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <PaginationComponent
                name="Teams"
                totalcount={totalcount}
                limit={limit}
                handleLimitChange={handleLimitChange}
                handlePrevPage={handlePrevPage}
                handleNextPage={handleNextPage}
                currentPage={currentPage}
                totalPages={totalPages}
              />
              {/* <div className="d-flex flex-wrap justify-content-between align-items-center m-3">
                <p className="fs-14 fw-bold mb-0">Total Teams: {totalcount}</p>
                <div>
                  <FormControl variant="outlined" size="small">
                    <InputLabel id="limit-label">Limit</InputLabel>
                    <Select
                      labelId="limit-label"
                      id="limit-select"
                      value={limit}
                      onChange={handleLimitChange}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                    </Select>
                  </FormControl>
                  <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <Iconify icon={"grommet-icons:previous"} />
                  </Button>
                  <Button onClick={handleNextPage} disabled={totalPages === 1}>
                    <Iconify icon={"grommet-icons:next"} />
                  </Button>
                </div>
              </div> */}
            </Card>
          </Container>

          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                p: 1,
                width: 140,
                "& .MuiMenuItem-root": {
                  px: 1,
                  typography: "body2",
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <MenuItem
              onClick={() => {
                Handleshow("View");
              }}
            >
              <Iconify icon={"iconoir:eye-solid"} sx={{ mr: 2 }} />
              View
            </MenuItem>
            <MenuItem
              onClick={() => {
                Handleshow("Edit");
              }}
            >
              <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
              Edit
            </MenuItem>
            <MenuItem
              sx={{ color: "error.main" }}
              onClick={() => {
                DeleteId(selectedUserId);
              }}
            >
              <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
              Delete
            </MenuItem>
          </Popover>
        </>
      )}
    </>
  );
};
export default AllTeam;
