import { useEffect, useState } from "react";
import Iconify from "../../../components/iconify/Iconify";
import SvgColor from "../../../components/svg-color";
import NavSection from "../../../components/nav-section/NavSection";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const NavConfigComponent = () => {
  const [navConfig, setNavConfig] = useState([]);

  const updateNavConfig = () => {
    const role = sessionStorage.getItem("isAuthenticated");
    console.log("role", role);

    if (role === "Admin") {
      setNavConfig([
        {
          title: "Dashboard",
          path: "/admin/dashboard",
          icon: <Iconify icon="carbon:dashboard-reference" />,
        },
        {
          title: "Role",
          icon: <Iconify icon="ri:user-star-line" />,
          children: [
            {
              title: "Admin",
              path: "/admin/super-admin",
              icon: <Iconify icon="mdi:administrator-outline" />,
            },
            {
              title: "Sub Admin",
              path: "/admin/sub-admin",
              icon: (
                <Iconify icon="material-symbols:admin-panel-settings-outline" />
              ),
            },
            {
              title: "Regional head",
              path: "/admin/regional-head",
              icon: <Iconify icon="streamline:vr-headset-1" />,
            },
            {
              title: "Area Sales Manager",
              path: "/admin/area-sales-manager",
              icon: <Iconify icon="ic:sharp-area-chart" />,
            },
            {
              title: "Relation Executive",
              path: "/admin/relation-executive",
              icon: <Iconify icon="mdi:face-agent" />,
            },
            {
              title: "Field Officer",
              path: "/admin/field-officer",
              icon: <Iconify icon="carbon:sales-ops" />,
            },
          ],
        },
        {
          title: "Team",
          icon: <Iconify icon="ph:microsoft-teams-logo-fill" />,
          children: [
            {
              title: "Create Team",
              path: "/admin/create-team",
              icon: <Iconify icon="fluent:people-team-add-20-filled" />,
            },
            {
              title: "All Team",
              path: "/admin/all-team",
              icon: <Iconify icon="fluent:people-team-32-filled" />,
            },
          ],
        },
        {
          title: "Leads",
          icon: <Iconify icon="clarity:thin-client-solid" />,
          children: [
            {
              title: "Customers",
              path: "/admin/customer-leads",
              icon: <Iconify icon="mdi:face-agent" />,
            },
            {
              title: "Clients",
              path: "/admin/client-leads",
              icon: <Iconify icon="jam:user-square" />,
            },
          ],
        },
        {
          title: "My Leads",
          icon: <Iconify icon="iconoir:user-love" />,
          children: [
            {
              title: "Customers",
              path: "/admin/customer-my-leads",
              icon: <Iconify icon="mdi:face-agent" />,
            },
            {
              title: "Clients",
              path: "/admin/client-my-leads",
              icon: <Iconify icon="jam:user-square" />,
            },
          ],
        },
        {
          title: "Plans",
          path: "/admin/plans",
          icon: <Iconify icon="ic:round-leak-add" />,
        },
        {
          title: "All Clients",
          path: "/admin/allclients",
          icon: <Iconify icon="oui:app-users-roles" />,
        },
        {
          title: "All Customers",
          path: "/admin/allcustomers",
          icon: <Iconify icon="tabler:user-heart" />,
        },
        {
          title: "All Notification",
          path: "/admin/notification",
          icon: <Iconify icon="ic:round-notifications-active" />,
        },
        {
          title: "Settings",
          path: "/admin/settings",
          icon: <Iconify icon="ant-design:setting-filled" />,
        },
      ]);
    } else if (role === "Sub Admin") {
      setNavConfig([
        {
          title: "Dashboard",
          path: "/sub-admin/dashboard",
          icon: <Iconify icon="carbon:dashboard-reference" />,
        },
        {
          title: "Role",
          icon: <Iconify icon="ri:user-star-line" />,
          children: [
            {
              title: "Regional head",
              path: "/sub-admin/regional-head",
              icon: <Iconify icon="streamline:vr-headset-1" />,
            },
            {
              title: "Area Sales Manager",
              path: "/sub-admin/area-sales-manager",
              icon: <Iconify icon="ic:sharp-area-chart" />,
            },
            {
              title: "Relation Executive",
              path: "/sub-admin/relation-executive",
              icon: <Iconify icon="mdi:face-agent" />,
            },
            {
              title: "Field Officer",
              path: "/sub-admin/field-officer",
              icon: <Iconify icon="carbon:sales-ops" />,
            },
          ],
        },
        {
          title: "Team",
          icon: <Iconify icon="ph:microsoft-teams-logo-fill" />,
          children: [
            {
              title: "Create Team",
              path: "/sub-admin/create-team",
              icon: <Iconify icon="fluent:people-team-add-20-filled" />,
            },
            {
              title: "All Team",
              path: "/sub-admin/all-team",
              icon: <Iconify icon="fluent:people-team-32-filled" />,
            },
          ],
        },
        {
          title: "Leads",
          icon: <Iconify icon="clarity:thin-client-solid" />,
          children: [
            {
              title: "Customers",
              path: "/sub-admin/customer-leads",
              icon: <Iconify icon="mdi:face-agent" />,
            },
            {
              title: "Clients",
              path: "/sub-admin/client-leads",
              icon: <Iconify icon="jam:user-square" />,
            },
          ],
        },
        {
          title: "My Leads",
          icon: <Iconify icon="iconoir:user-love" />,
          children: [
            {
              title: "Customers",
              path: "/sub-admin/customer-my-leads",
              icon: <Iconify icon="mdi:face-agent" />,
            },
            {
              title: "Clients",
              path: "/sub-admin/client-my-leads",
              icon: <Iconify icon="jam:user-square" />,
            },
          ],
        },
        {
          title: "All Clients",
          path: "/sub-admin/allclients",
          icon: <Iconify icon="oui:app-users-roles" />,
        },
        {
          title: "All Customers",
          path: "/sub-admin/allcustomers",
          icon: <Iconify icon="tabler:user-heart" />,
        },
        {
          title: "All Notification",
          path: "/sub-admin/notification",
          icon: <Iconify icon="ic:round-notifications-active" />,
        },
      ]);
    } else if (role === "Regional Head") {
      setNavConfig([
        {
          title: "Dashboard",
          path: "/regional-head/dashboard",
          icon: <Iconify icon="carbon:dashboard-reference" />,
        },
        {
          title: "Leads",
          icon: <Iconify icon="clarity:thin-client-solid" />,
          children: [
            {
              title: "Customers",
              path: "/regional-head/customer-leads",
              icon: <Iconify icon="mdi:face-agent" />,
            },
            {
              title: "Clients",
              path: "/regional-head/client-leads",
              icon: <Iconify icon="jam:user-square" />,
            },
          ],
        },
        {
          title: "My Leads",
          icon: <Iconify icon="iconoir:user-love" />,
          children: [
            {
              title: "Customers",
              path: "/regional-head/customer-my-leads",
              icon: <Iconify icon="mdi:face-agent" />,
            },
            {
              title: "Clients",
              path: "/regional-head/client-my-leads",
              icon: <Iconify icon="jam:user-square" />,
            },
          ],
        },
        {
          title: "All Clients",
          path: "/regional-head/allclients",
          icon: <Iconify icon="oui:app-users-roles" />,
        },
        {
          title: "All Customers",
          path: "/regional-head/allcustomers",
          icon: <Iconify icon="tabler:user-heart" />,
        },
      ]);
    } else if (role === "Area Sales Manager") {
      setNavConfig([
        {
          title: "Dashboard",
          path: "/area-sales-manager/dashboard",
          icon: <Iconify icon="carbon:dashboard-reference" />,
        },
        {
          title: "Leads",
          icon: <Iconify icon="clarity:thin-client-solid" />,
          children: [
            {
              title: "Customers",
              path: "/area-sales-manager/customer-leads",
              icon: <Iconify icon="mdi:face-agent" />,
            },
            {
              title: "Clients",
              path: "/area-sales-manager/client-leads",
              icon: <Iconify icon="jam:user-square" />,
            },
          ],
        },
        {
          title: "My Leads",
          icon: <Iconify icon="iconoir:user-love" />,
          children: [
            {
              title: "Customers",
              path: "/area-sales-manager/customer-my-leads",
              icon: <Iconify icon="mdi:face-agent" />,
            },
            {
              title: "Clients",
              path: "/area-sales-manager/client-my-leads",
              icon: <Iconify icon="jam:user-square" />,
            },
          ],
        },
        {
          title: "All Clients",
          path: "/area-sales-manager/allclients",
          icon: <Iconify icon="oui:app-users-roles" />,
        },
        {
          title: "All Customers",
          path: "/area-sales-manager/allcustomers",
          icon: <Iconify icon="tabler:user-heart" />,
        },
      ]);
    } else if (role === "Relation Executive") {
      setNavConfig([
        {
          title: "Dashboard",
          path: "/relation-executive/dashboard",
          icon: <Iconify icon="carbon:dashboard-reference" />,
        },
        {
          title: "Leads",
          icon: <Iconify icon="clarity:thin-client-solid" />,
          children: [
            {
              title: "Customers",
              path: "/relation-executive/customer-leads",
              icon: <Iconify icon="mdi:face-agent" />,
            },
            {
              title: "Clients",
              path: "/relation-executive/client-leads",
              icon: <Iconify icon="jam:user-square" />,
            },
          ],
        },
        {
          title: "My Leads",
          icon: <Iconify icon="iconoir:user-love" />,
          children: [
            {
              title: "Customers",
              path: "/relation-executive/customer-my-leads",
              icon: <Iconify icon="mdi:face-agent" />,
            },
            {
              title: "Clients",
              path: "/relation-executive/client-my-leads",
              icon: <Iconify icon="jam:user-square" />,
            },
          ],
        },
      ]);
    } else {
      setNavConfig([
        {
          title: "Dashboard",
          path: "/tele-caller/dashboard",
          icon: <Iconify icon="carbon:dashboard-reference" />,
        },
        {
          title: "Leads",
          icon: <Iconify icon="clarity:thin-client-solid" />,
          children: [
            {
              title: "Customers",
              path: "/tele-caller/customer-leads",
              icon: <Iconify icon="mdi:face-agent" />,
            },
            {
              title: "Clients",
              path: "/tele-caller/client-leads",
              icon: <Iconify icon="jam:user-square" />,
            },
          ],
        },
        {
          title: "My Leads",
          icon: <Iconify icon="iconoir:user-love" />,
          children: [
            {
              title: "Customers",
              path: "/tele-caller/customer-my-leads",
              icon: <Iconify icon="mdi:face-agent" />,
            },
            {
              title: "Clients",
              path: "/tele-caller/client-my-leads",
              icon: <Iconify icon="jam:user-square" />,
            },
          ],
        },
      ]);
    }
  };

  useEffect(() => {
    updateNavConfig();

    const handleStorageChange = () => {
      updateNavConfig();
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return <NavSection data={navConfig} />;
};

export default NavConfigComponent;
