import {
  Button,
  Card,
  Container,
  MenuItem,
  Stack,
  TextField,
  Typography,
  FormControl,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import Iconify from "../../components/iconify";

function AddCustomerLead({ handleaddcustomerClose, action }) {
  const [formData, setFormData] = useState({
    email: "",
    PhoneNumber: "",
    companyName: "",
    jobTitle: "",
    companyInformation: "",
    address: "",
    adType: "",
    demographicInformation: "",
    adTitle: "",
    adDuration: "",
    adFrequency: "",
    adSize: "",
    homePage: "",
    specifyCategory: "",
    articlePage: "",
    additionalRequirements: "",
    adDesign: "",
    animation: "",
    designServices: "",
    specialInstructions: "",
    sequencePricing: "",
    businessType: "",
    localityID: "",
    screenID: "",
    fromdate: "",
    todate: "",
    Details: "",
    Image: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleDateChange = (range) => {
    if (range && range.length === 2) {
      const [fromdate, todate] = range;

      // Format the date in local time
      const formatToLocalISO = (date) => {
        const tzOffset = -date.getTimezoneOffset() * 60000; // offset in milliseconds
        return new Date(date.getTime() + tzOffset).toISOString().slice(0, -1);
      };

      setFormData((prevData) => ({
        ...prevData,
        fromdate: formatToLocalISO(fromdate),
        todate: formatToLocalISO(todate),
      }));
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      console.log(formData, "formData");
    }
  };
  const menuStyles = {
    marginTop: 10,
    maxHeight: 200,
  };

  const validateForm = () => {
    let tempErrors = {};
    let valid = true;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      tempErrors.email = "Email is required";
      valid = false;
    } else if (!emailRegex.test(formData.email)) {
      tempErrors.email = "Enter a valid email";
      valid = false;
    }

    if (!formData.phonenumber) {
      tempErrors.phonenumber = "Phone Number is required";
      valid = false;
    } else if (!/^\d{10}$/.test(formData.phonenumber)) {
      tempErrors.phonenumber = "Phone Number must be exactly 10 digits";
      valid = false;
    }
    if (!formData.companyName) {
      tempErrors.companyName = "Company Name is required";
      valid = false;
    }

    if (!formData.address) {
      tempErrors.address = "Address is required";
      valid = false;
    }
    if (!formData.adTitle) {
      tempErrors.adTitle = "Ad Title is required";
      valid = false;
    }
    if (!formData.adDuration) {
      tempErrors.adDuration = "Ad Duration is required";
      valid = false;
    }
    if (!formData.sequencePricing) {
      tempErrors.sequencePricing = "Sequence Pricing is required";
      valid = false;
    }

    if (!formData.businessType) {
      tempErrors.businessType = "Business Type is required";
      valid = false;
    }

    if (!formData.localityID) {
      tempErrors.localityID = "Locality ID is required";
      valid = false;
    }

    if (!formData.screenID) {
      tempErrors.screenID = "Screen ID is required";
      valid = false;
    }

    if (!formData.Image || !formData.Image) {
      tempErrors.Image = "Image is required";
      valid = false;
    }

    if (!formData.fromdate || !formData.fromdate) {
      tempErrors.fromdate = "Date is required";
      valid = false;
    }

    if (!formData.todate || !formData.todate) {
      tempErrors.todate = "Date is required";
      valid = false;
    }

    setErrors(tempErrors);
    return valid;
  };

  return (
    <>
      <Container>
        <Card className="p-3">
          <h5 className="fw-bold d-flex align-content-center">
            <Iconify
              icon="mdi:arrow-back-circle"
              className="cursor-pointer me-2"
              onClick={handleaddcustomerClose}
              style={{ height: "30px", width: "30px" }}
            />{" "}
            <span className="mt-1">{action} Customer Lead</span>
          </h5>

          <div className="d-flex flex-wrap">  
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="email"
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                margin="normal"
                autoComplete="off"
                size="small"
                error={!!errors.email}
                helperText={errors.email}
                required
              />
            </div>
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="number"
                label="Phone Number"
                name="phonenumber"
                value={formData.phonenumber}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    handleChange(e);
                  }
                }}
                margin="normal"
                size="small"
                error={!!errors.phonenumber}
                helperText={errors.phonenumber}
                required
              />
            </div>
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="text"
                label="Company Name"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.companyName}
                helperText={errors.companyName}
                required
              />
            </div>
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="text"
                label="Job Title"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.jobTitle}
                helperText={errors.jobTitle}
              />
            </div>
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="text"
                label="Company Information"
                name="companyInformation"
                value={formData.companyInformation}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.companyInformation}
                helperText={errors.companyInformation}
              />
            </div>
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="text"
                label="Address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.address}
                helperText={errors.address}
                required
              />
            </div>
            <div className="col-3 px-1">
              <TextField
                select
                label="Ad Type"
                name="adType"
                value={formData.adType}
                onChange={handleChange}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                className="mb-0 mt-3"
                style={{ width: "100%" }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: menuStyles,
                    },
                  },
                }}
                error={!!errors.adType}
                helperText={errors.adType}
              >
                <MenuItem value="banner">Banner</MenuItem>
                <MenuItem value="sidebar">Side Bar</MenuItem>
                <MenuItem value="popup">Pop-Up</MenuItem>
                <MenuItem value="video">Video</MenuItem>
                <MenuItem value="sponsoredcontent">Sponsored Content</MenuItem>
              </TextField>
            </div>
            <div className="col-3 px-1">
              <TextField
                select
                label="Demographic Information"
                name="demographicInformation"
                value={formData.demographicInformation}
                onChange={handleChange}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                className="mb-0 mt-3"
                style={{ width: "100%" }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: menuStyles,
                    },
                  },
                }}
                error={!!errors.demographicInformation}
                helperText={errors.demographicInformation}
              >
                <MenuItem value="residential">Residential</MenuItem>
                <MenuItem value="commercial">Commercial</MenuItem>
                <MenuItem value="corporatecompany">Corporate Company</MenuItem>
                <MenuItem value="eventplace">Event Place</MenuItem>
                <MenuItem value="interests">Interests</MenuItem>
              </TextField>
            </div>
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="text"
                label="AD Title"
                name="adTitle"
                value={formData.adTitle}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.adTitle}
                helperText={errors.adTitle}
                required
              />
            </div>
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="text"
                label="AD Duration"
                name="adDuration"
                value={formData.adDuration}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.adDuration}
                helperText={errors.adDuration}
                required
              />
            </div>
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="text"
                label="AD Frequency"
                name="adFrequency"
                value={formData.adFrequency}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.adFrequency}
                helperText={errors.adFrequency}
              />
            </div>
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="text"
                label="AD Size"
                name="adSize"
                value={formData.adSize}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.adSize}
                helperText={errors.adSize}
              />
            </div>
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="text"
                label="Home Page"
                name="homePage"
                value={formData.homePage}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.homePage}
                helperText={errors.homePage}
              />
            </div>
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="text"
                label="Specify Category"
                name="specifyCategory"
                value={formData.specifyCategory}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.specifyCategory}
                helperText={errors.specifyCategory}
              />
            </div>
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="text"
                label="Article Page"
                name="articlePage"
                value={formData.articlePage}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.articlePage}
                helperText={errors.articlePage}
              />
            </div>
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="text"
                label="Additional Requirements"
                name="additionalRequirements"
                value={formData.additionalRequirements}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.additionalRequirements}
                helperText={errors.additionalRequirements}
              />
            </div>
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="text"
                label="Special Instructions"
                name="specialInstructions"
                value={formData.specialInstructions}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.specialInstructions}
                helperText={errors.specialInstructions}
              />
            </div>
            <div className="col-3 px-1">
              <TextField
                select
                label="Animation"
                name="animation"
                value={formData.animation}
                onChange={handleChange}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                className="mb-0 mt-3"
                style={{ width: "100%" }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: menuStyles,
                    },
                  },
                }}
                error={!!errors.animation}
                helperText={errors.animation}
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </TextField>
            </div>
            <div className="col-3 px-1">
              <TextField
                select
                label="Ad Design"
                name="adDesign"
                value={formData.adDesign}
                onChange={handleChange}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                className="mb-0 mt-3"
                style={{ width: "100%" }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: menuStyles,
                    },
                  },
                }}
                error={!!errors.adDesign}
                helperText={errors.adDesign}
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </TextField>
            </div>
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="text"
                label="Design Services"
                name="designServices"
                value={formData.designServices}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.designServices}
                helperText={errors.designServices}
              />
            </div>
            <div className="col-3 px-1">
              <TextField
                select
                label="Sequence Pricing"
                name="sequencePricing"
                value={formData.sequencePricing}
                onChange={handleChange}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                className="mb-0 mt-3"
                style={{ width: "100%" }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: menuStyles,
                    },
                  },
                }}
                error={!!errors.sequencePricing}
                helperText={errors.sequencePricing}
                required
              >
                <MenuItem value="gold-commercial">
                  Gold - Commercial - Rs.250.00
                </MenuItem>
                <MenuItem value="gold-residential">
                  Gold - Residential - Rs.90.00
                </MenuItem>
                <MenuItem value="silver-commercial">
                  Silver - Commercial - Rs.200.00
                </MenuItem>
                <MenuItem value="silver-residential">
                  Silver - Residential - Rs.40.00
                </MenuItem>
                <MenuItem value="elite-residential-advanced">
                  Elite - Residential Screens - Advanced Pack - Rs.5000.00
                </MenuItem>
              </TextField>
            </div>
            <div className="col-3 px-1">
              <TextField
                select
                label="Business Type"
                name="businessType"
                value={formData.businessType}
                onChange={handleChange}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                className="mb-0 mt-3"
                style={{ width: "100%" }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: menuStyles,
                    },
                  },
                }}
                error={!!errors.businessType}
                helperText={errors.businessType}
                required
              >
                <MenuItem value="Agriculture">Agriculture</MenuItem>
                <MenuItem value="Arts and Entertainment">
                  Arts and Entertainment
                </MenuItem>
                <MenuItem value="Construction">Construction</MenuItem>
                <MenuItem value="Education">Education</MenuItem>
                <MenuItem value="Finance and Insurance">
                  Finance and Insurance
                </MenuItem>
                <MenuItem value="Food and Beverage">Food and Beverage</MenuItem>
                <MenuItem value="Healthcare and Social Assistance">
                  Healthcare and Social Assistance
                </MenuItem>
                <MenuItem value="Hospitality">Hospitality</MenuItem>
                <MenuItem value="Information Technology">
                  Information Technology
                </MenuItem>
                <MenuItem value="Manufacturing">Manufacturing</MenuItem>
                <MenuItem value="Professional Services">
                  Professional Services
                </MenuItem>
                <MenuItem value="Real Estate">Real Estate</MenuItem>
                <MenuItem value="Retail">Retail</MenuItem>
                <MenuItem value="Transportation and Logistics">
                  Transportation and Logistics
                </MenuItem>
                <MenuItem value="Utilities">Utilities</MenuItem>
                <MenuItem value="Wholesale">Wholesale</MenuItem>
              </TextField>
            </div>
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="text"
                label="Locality ID"
                name="localityID"
                value={formData.localityID}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.localityID}
                helperText={errors.localityID}
                required
              />
            </div>
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="text"
                label="Screen ID"
                name="screenID"
                value={formData.screenID}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.screenID}
                helperText={errors.screenID}
                required
              />
            </div>
            <div className="col-3 px-1">
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <DateRangePicker
                  margin="normal"
                  size="small"
                  fullWidth
                  appearance="default"
                  placeholder="Select start and end date *"
                  onChange={handleDateChange}
                />
              </div>
              <div>
                {(errors.fromdate || errors.todate) && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "0.75rem",
                      marginLeft: "14px",
                    }}
                  >
                    {errors.fromdate || errors.todate}
                  </span>
                )}
              </div>
            </div>
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="text"
                label="Details"
                name="Details"
                value={formData.Details}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.Details}
                helperText={errors.Details}
              />
            </div>
            <div className="col-3 px-1">
              <TextField
                fullWidth
                type="file"
                label="Image"
                name="Image"
                value={formData.Image}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.Image}
                helperText={errors.Image}
                inputProps={{
                  accept: "image/*",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </div>
            <div className="col-3 px-1"></div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <Button
              variant="contained"
              color="error"
              className="mx-3 btn-danger"
              onClick={handleaddcustomerClose}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="btn-green"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Card>
      </Container>
    </>
  );
}

export default AddCustomerLead;
