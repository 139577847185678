import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";
// components
import Logo from "../components/logo";
// sections
import { LoginForm } from "../sections/auth/login";
import backgroundimage from "../components/logo/backgroundimage-2608.webp";
import SwiperLogin from "../components/Swiper";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  background: "#f9fafb",
  borderRadius: "10px",
  padding: theme.spacing(5, 5),
}));

const MainContent = styled("div")(() => ({
  backgroundImage: `linear-gradient(1deg, rgb(71 71 71), rgb(5 5 5 / 48%)) center center / cover no-repeat local)`,
  // backgroundImage: `url(${backgroundimage})`,
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "local",
  backgroundPosition: "center",
  backgroundSize: "cover",
  width: "100%",
  display: "flex",
  height: "100vh",
  justifyContent: "center",
  alignItems: "center",
}));

// ----------------------------------------------------------------------

export default function LoginPage({ onLogin }) {
  return (
    <>
      <Helmet>
        <title> Login </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: "fixed",
            top: { xs: 16, sm: 24, md: 40 },
            right: { xs: 16, sm: 24, md: 30 },
          }}
        />
        <div
          className="w-100 d-flex flex-warp align-items-center justify-content-center"
          style={{ height: "100vh", backgroundColor: "#f9fafb" }}
        >
          <MainContent
            className="col-8"
            style={{ borderRadius: "0px 90px 90px 0px", overflow: "hidden" }}
          >
            <SwiperLogin />
          </MainContent>
          <div className="col-4 position-relative">
            <div className="design-login"></div>
            <StyledContent>
              <LoginForm onLogin={onLogin} />
            </StyledContent>
          </div>
        </div>
      </StyledRoot>
    </>
  );
}
