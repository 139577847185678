import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";

// Styled component for Welcome Box
const Box = styled.div`
  background-color: #8f79fa;
  color: #f5f5ffcc;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1) !important;
  padding: 10px;
  margin: 2px;
  text-align: center;
`;

// Main component with Swiper integration
const WelcomeSwiper = () => {
  const data = [
    { title: "Clients", count: 200 },
    { title: "Customers", count: 500 },
    { title: "Sub Admins", count: 2 },
    { title: "Regional Head", count: 90 },
    { title: "Area Sales Manager", count: 90 },
    { title: "Relation Executives", count: 20 },
    { title: "Field Officers", count: 50 },
  ];

  return (
    <Swiper
      modules={[Autoplay]}
      autoplay={{ delay: 3000 }}
      spaceBetween={10}
      slidesPerView={4}
      loop={true}
      breakpoints={{
        480: { slidesPerView: 1 },
        768: { slidesPerView: 2 },
        1024: { slidesPerView: 4 },
      }}
      // className="mySwiper"
    >
      {data.map((item, index) => (
        <SwiperSlide key={index}>
          <Box>
            <h6>{item.title}</h6>
            <p className="fw-bold text-white mb-0 py-1">{item.count}</p>
          </Box>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default WelcomeSwiper;
