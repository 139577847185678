import { Card } from "@mui/material";
import React from "react";
import styled from "styled-components";

const Box = styled.div`
  background-color: #846cf91a;
  height: 60vh;
  border-radius: 5px;
  overflow-y: auto;
  position: relative;
`;
const History = styled.div`
  background-color: #d7d5ff;
  border-radius: 5px;
  margin: 10px;
  position: relative;
`;
const Text = styled.h5`
  background-color: #f9fafbcc;
  position: sticky;
  top: 0;
  z-index: 99999;
  padding: 10px 16px;
`;
const Dot = styled.span`
  position: absolute;
  height: 10px;
  width: 10px;
  left: -5%;
  top: 4%;
  border-radius: 50%;
  background-color: #b9b4fe;

  &::before {
    content: "";
    height: 105px;
    width: 1px;
    background: #b4b2e3;
    position: absolute;
    top: 16px;
    left: 4px;
  }
`;
const Remark = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  height: 40px;
  -webkit-box-orient: vertical;
`;

function LeadHistory() {
  return (
    <Box className="pt-0 pb-3">
      <Text>History</Text>
      <History className="p-3 ms-4">
        <Dot />
        <div className="d-flex justify-content-between">
          <span className="fw-bold col-6">Pending</span>
          <p className="fs-14 mb-0 col-6 d-flex justify-content-end">
            Maari Vicky
          </p>
        </div>
        <Remark className="fs-12 mb-0 pt-2">
          ddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfj
          ddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfj
          ddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlk
          fjddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj
        </Remark>
        <p className="fs-12 mb-0 mt-1">updated on : 27-02-2024</p>
      </History>

      <History className="p-3 ms-4">
        <Dot />
        <div className="d-flex justify-content-between">
          <span className="fw-bold col-6">Pending</span>
          <p className="fs-14 mb-0 col-6 d-flex justify-content-end">
            Maari Vicky
          </p>
        </div>
        <Remark className="fs-12 mb-0 pt-2">
          ddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfj
          ddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfj
          ddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlk
          fjddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj
        </Remark>
        <p className="fs-12 mb-0 mt-1">updated on : 27-02-2024</p>
      </History>

      <History className="p-3 ms-4">
        <Dot />
        <div className="d-flex justify-content-between">
          <span className="fw-bold col-6">Pending</span>
          <p className="fs-14 mb-0 col-6 d-flex justify-content-end">
            Maari Vicky
          </p>
        </div>
        <Remark className="fs-12 mb-0 pt-2">
          ddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfj
          ddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfj
          ddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlk
          fjddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj
        </Remark>
        <p className="fs-12 mb-0 mt-1">updated on : 27-02-2024</p>
      </History>
    </Box>
  );
}

export default LeadHistory;
