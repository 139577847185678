const USERSROUTES = {
  LOGIN: "user/login",
  SIGNOUT: "users/signout",

  AddUSER: "user/signUp",
  GETUSER: "users",
  DELETEUSER: (USER, ID) => `user/delete/${ID}`,
  PATCHUSER: (USER, ID) => `userUpdate/${ID}`,
  USERAPPROVAL: `users/agent/approval`,
};

export default USERSROUTES;
