import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import Scrollbar from "../scrollbar/Scrollbar";

function UpcomingDeadline() {
  const Data = [
    {
      name: "Ram",
      plan: "Silver",
      companyName: "Ganesh Solutions",
      adPlace: "Anna Nagar, VR Mall",
      endDate: "31-11-2024",
    },
    {
      name: "Kannan",
      plan: "Gold",
      companyName: "Ganesh Solutions",
      adPlace: "Anna Nagar, VR Mall",
      endDate: "20-11-2024",
    },
    {
      name: "Selvam",
      plan: "Silver",
      companyName: "Ganesh Solutions",
      adPlace: "Anna Nagar, VR Mall",
      endDate: "14-11-2024",
    },
    {
      name: "Selvam",
      plan: "Silver",
      companyName: "Ganesh Solutions",
      adPlace: "Anna Nagar, VR Mall",
      endDate: "14-11-2024",
    },
    {
      name: "Selvam",
      plan: "Silver",
      companyName: "Ganesh Solutions",
      adPlace: "Anna Nagar, VR Mall",
      endDate: "14-11-2024",
    },
    {
      name: "Selvam",
      plan: "Silver",
      companyName: "Ganesh Solutions",
      adPlace: "Anna Nagar, VR Mall",
      endDate: "14-11-2024",
    },
  ];
  return (
    <>
      <Card className="py-3">
        <h5 className="px-3 mb-3">Upcoming Deadlines</h5>
        <Scrollbar>
          <TableContainer className="w-100" style={{ height: "350px" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Current Plan</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell>Current AD Place</TableCell>
                  <TableCell>End Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Data.map((data, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{data.name}</TableCell>
                      <TableCell>{data.plan}</TableCell>
                      <TableCell>{data.companyName}</TableCell>
                      <TableCell>{data.adPlace}</TableCell>
                      <TableCell className="text-danger">
                        {data.endDate}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
}

export default UpcomingDeadline;
