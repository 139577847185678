import React from "react";
import { Slide } from "@mui/material";

const SlideUpTransition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{
        enter: 1000,
        exit: 1000,
      }}
      style={{
        transitionDelay: props.in ? "500ms" : "500ms",
      }}
    />
  );
});

export default SlideUpTransition;
