import React, { useState, useEffect } from "react";
import {
  TableBody,
  TextField,
  MenuItem,
  Button,
  TableHead,
  TableContainer,
  Table,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  getAllCountries,
  getStatesByCountryId,
  getDistrictsByStateId,
} from "@coffeebeanslabs/country_state_district";
import Iconify from "../iconify/Iconify";

import {
  deleteData,
  getData,
  postData,
  putData,
} from "../../webService/webService";
import LOCATIONROUTES from "../../API_Routes/Location/Location_Routers";
import StateData from "../../utils/stateDistrict";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { showConfirmationDialog } from "../../utils/sweetalertUtils";

const LocationForm = () => {
  const [Data, setData] = useState([]);
  const dispatch = useDispatch();
  const accessToken = sessionStorage.getItem("Token");
  // States for form fields
  const [locationId, setLocationId] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [selectedState, setSelectedState] = useState("");
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [open, setOpen] = useState(false);
  const [editingLocation, setEditingLocation] = useState(null);
  const handletoOpen = () => {
    setOpen(true);
  };
  const handletoClose = () => {
    setOpen(false);
  };
  // Define static zone options
  const zones = ["East", "West", "North", "South"];

  // Handle state selection
  const handleStateChange = (event) => {
    const state = event.target.value;
    setSelectedState(state);

    // Find the districts for the selected state
    const selectedStateData = StateData.find((item) => item.state === state);
    setDistricts(selectedStateData ? selectedStateData.districts : []);
  };

  useEffect(() => {
    if (selectedState) {
      const selectedStateData = StateData.find(
        (item) => item.state === selectedState
      );
      setDistricts(selectedStateData ? selectedStateData.districts : []);
    }
  }, [selectedState]);

  const handleDistrictChange = (event) => {
    setSelectedDistrict(event.target.value);
  };

  // Handle zone change
  const handleZoneChange = (event) => {
    setSelectedZone(event.target.value);
  };

  // Fetch all locations
  const fetchdata = () => {
    getData(LOCATIONROUTES.GETLOCATION, null, accessToken)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchdata();
  }, []);

  // Handle Edit
  const handleEditLocation = (location) => {
    setLocationId(location._id);
    setSelectedCountry(location.country);
    setSelectedState(location.state);
    setSelectedDistrict(location.district);
    setSelectedZone(location.zoneName);
    setOpen(true);
    setEditingLocation(location);
  };

  // Handle Delete
  const DeleteLocation = async (locationId) => {
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      deleteData(
        LOCATIONROUTES.DELETELOCATION(locationId),
        accessToken,
        dispatch
      )
        .then((res) => {
          setLocationId("");
          fetchdata();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handlesubmit = () => {
    const params = {
      country: selectedCountry,
      state: selectedState,
      district: selectedDistrict,
      zoneName: selectedZone,
    };
    if (locationId) {
      putData(
        LOCATIONROUTES.PUTLOCATION(locationId),
        params,
        accessToken,
        dispatch
      )
        .then((res) => {
          setSelectedCountry("India");
          setSelectedState("");
          setDistricts([]);
          setSelectedDistrict("");
          setSelectedZone("");
          fetchdata();
          setLocationId("");
          setOpen(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      postData(LOCATIONROUTES.AddLOCATION, params, accessToken, dispatch)
        .then((res) => {
          setSelectedCountry("India");
          setSelectedState("");
          setDistricts([]);
          setSelectedDistrict("");
          setSelectedZone("");
          setLocationId("");
          fetchdata();
          setOpen(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <div>
        <div className="d-flex flex-wrap justify-content-end">
          {!open ? (
            <Button
              variant="contained"
              className="main-col-new"
              onClick={() => handletoOpen()}
            >
              Add Location
            </Button>
          ) : (
            <Button
              variant="contained"
              className="main-col-new"
              onClick={() => handletoClose()}
            >
              Close Location
            </Button>
          )}
        </div>
        {open && (
          <div className="d-flex flex-wrap justify-content-between align-items-center my-2">
            <div className="col-md-3 col-sm-6 col-12 my-1 px-1">
              <TextField
                select
                label="State"
                value={selectedState}
                onChange={handleStateChange}
                fullWidth
                size="small"
                variant="outlined"
                margin="normal"
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        overflowY: "auto",
                      },
                    },
                  },
                }}
              >
                {StateData?.map((stateItem, index) => (
                  <MenuItem key={index} value={stateItem.state}>
                    {stateItem.state}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-md-3 col-sm-6 col-12 my-1 px-1">
              <TextField
                select
                label="District"
                value={selectedDistrict}
                onChange={handleDistrictChange}
                fullWidth
                size="small"
                variant="outlined"
                margin="normal"
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        overflowY: "auto",
                      },
                    },
                  },
                }}
                disabled={!selectedState}
              >
                {districts?.map((district, index) => (
                  <MenuItem key={index} value={district}>
                    {district}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-md-3 col-sm-6 col-12 my-1 px-1">
              <TextField
                select
                label="Zone"
                value={selectedZone}
                onChange={handleZoneChange}
                fullWidth
                size="small"
                variant="outlined"
                margin="normal"
              >
                {zones.map((zone) => (
                  <MenuItem key={zone} value={zone}>
                    {zone}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-md-3 col-sm-6 col-12 my-1 px-1">
              <Button
                variant="contained"
                color="primary"
                className="btn-green"
                onClick={() => handlesubmit()}
              >
                {locationId ? "Update" : "Add"}
              </Button>
            </div>
          </div>
        )}

        <TableContainer
          style={{ borderRadius: "10px" }}
          className="mt-4 mb-2 h-350 "
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>S.No</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>State</TableCell>
                <TableCell>District</TableCell>
                <TableCell>Zone</TableCell>
                <TableCell align="center">Edit</TableCell>
                <TableCell align="center">Delete</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {Data?.map((data, index) => (
                <TableRow key={data._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{data.country}</TableCell>
                  <TableCell>{data.state}</TableCell>
                  <TableCell>{data.district}</TableCell>
                  <TableCell>{data.zoneName}</TableCell>
                  <TableCell
                    onClick={() => handleEditLocation(data)}
                    align="center"
                    className="cursor-pointer"
                  >
                    <Iconify icon="uil:edit" className="text-primary" />
                  </TableCell>
                  <TableCell
                    onClick={() => DeleteLocation(data._id)}
                    align="center"
                    className="cursor-pointer"
                  >
                    <Iconify
                      icon="fluent:delete-32-regular"
                      className="text-danger"
                    />
                  </TableCell>
                </TableRow>
              ))}
              {Data?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    No Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default LocationForm;
