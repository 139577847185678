import {
  Card,
  Container,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import LocationForm from "../../components/CountryList/LocationForm";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BannerImages from "../../components/banner/BannerImages";
import { useState } from "react";
import Iconify from "../../components/iconify";

function Setting() {
  const [expanded, setExpanded] = useState(false); // Track the expanded accordion

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false); // If the same accordion is clicked, collapse it
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Settings
        </Typography>
      </Stack>
      <div className="d-flex flex-wrap justify-content-between">
        {/* Accordion for BannerImages */}
        <Accordion
          className="w-100 my-2 accordion-box"
          expanded={expanded === "panel1"} // Only expand if expanded state is "panel1"
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<Iconify icon="mingcute:up-line" />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography className="fw-bold">Banner Images</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BannerImages />
          </AccordionDetails>
        </Accordion>

        {/* Accordion for LocationForm */}
        <Accordion
          className="w-100 my-2 accordion-box"
          expanded={expanded === "panel2"} // Only expand if expanded state is "panel2"
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<Iconify icon="mingcute:up-line" />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold">Location</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LocationForm />
          </AccordionDetails>
        </Accordion>
      </div>
    </Container>
  );
}

export default Setting;
