import {
  Button,
  Card,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import Iconify from "../../components/iconify/Iconify";
import Scrollbar from "../../components/scrollbar/Scrollbar";
import SlideUpTransition from "../../utils/SlideUpTransition";
import {
  deleteData,
  getData,
  postData,
  putData,
} from "../../webService/webService";
import PLANROUTES from "../../API_Routes/User/Plan_Routers";
import { useDispatch } from "react-redux";
import { showConfirmationDialog } from "../../utils/sweetalertUtils";

function PlansDetails() {
  const Data = [
    {
      _id: 1,
      adType: "Residential Screens",
      planName: "Platinum",
      noOfRotationsPerDay: "145",
      slotDuration: "10Mins",
      noOfChannels: "3 Per Area",
      timing: "6 AM - 12 PM",
      loopTime: "12Mins",
      adFormat: "Mp4",
      ratePerDay: "123",
      gst: true,
      costonly: true,
    },
    {
      _id: 2,
      adType: "Commercial  Screens",
      planName: "Silver",
      noOfChannels: "3 Per Area",
      noOfRotationsPerDay: "145",
      slotDuration: "10Mins",
      timing: "6 AM - 12 AM",
      loopTime: "123Mins",
      adFormat: "Jpeg,Webp,MP4",
      ratePerDay: "150",
      gst: true,
      costonly: false,
    },
    {
      _id: 3,
      adType: "Commercial Screens Advanced Pack",
      planName: "Basic",
      noOfRotationsPerDay: "145",
      slotDuration: "10Mins",
      noOfChannels: "3 Per Area",
      timing: "6 AM - 12 PM",
      loopTime: "12Mins",
      adFormat: "Mp4",
      ratePerDay: "123",
      gst: true,
      costonly: true,
    },
    {
      _id: 4,
      adType: "Residential Screens  Advanced Pack",
      planName: "Silver",
      noOfChannels: "3 Per Area",
      noOfRotationsPerDay: "145",
      slotDuration: "34Mins",
      timing: "6 AM - 12 AM",
      loopTime: "123Mins",
      adFormat: "Jpeg,Webp,MP4",
      ratePerDay: "150",
      gst: true,
      costonly: false,
    },
    {
      _id: 5,
      adType: "Residential Slot",
      planName: "Basic",
      noOfChannels: "3 Per Area",
      noOfRotationsPerDay: "145",
      slotDuration: "10Mins",
      timing: "6 AM - 12 PM",
      loopTime: "12Mins",
      adFormat: "Mp4",
      ratePerDay: "123",
      gst: true,
      costonly: true,
    },
    {
      _id: 6,
      adType: "Commercial Slot",
      planName: "Silver",
      noOfChannels: "3 Per Area",
      noOfRotationsPerDay: "145",
      slotDuration: "34Mins",
      timing: "6 AM - 12 AM",
      loopTime: "123Mins",
      adFormat: "Jpeg,Webp,MP4",
      ratePerDay: "150",
      gst: true,
      costonly: false,
    },
  ];
  const menuStyles = {
    marginTop: 10,
    maxHeight: 200,
  };
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const accessToken = sessionStorage.getItem("Token");
  const [openModal, setOpenModal] = useState(false);
  const [action, setAction] = useState("");
  const [activeData, setActiveData] = useState();

  const handleClickOpenModal = (data, val) => {
    setAction(data);
    setActiveData(val);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setAction("");
    setFormData({
      adType: "",
      planName: "",
      noOfRotationsPerDay: "",
      slotDuration: "",
      timing: "",
      loopTime: "",
      adFormat: "",
      ratePerDay: "",
      gst: false,
      costonly: false,
    });
    setOpenModal(false);
  };

  // validations-start
  const [formData, setFormData] = useState({
    adType: "",
    planName: "",
    noOfRotationsPerDay: "",
    slotDuration: "",
    noOfChannels: "",
    timing: "",
    loopTime: "",
    adFormat: "",
    ratePerDay: "",
    gst: false,
    costonly: false,
  });
  const [errors, setErrors] = useState({
    adType: "",
    planName: "",
    noOfRotationsPerDay: "",
    slotDuration: "",
    noOfChannels: "",
    timing: "",
    loopTime: "",
    adFormat: "",
    ratePerDay: "",
  });
  const Type = [
    "Residential Screens",
    "Commercial  Screens",
    "Residential Slot",
    "Commercial Slot",
    "Residential Screens  Advanced Pack",
    "Commercial Screens Advanced Pack",
  ];
  const planNames = [
    "Platinum",
    "Gold",
    "Silver",
    "Video",
    "Premiem",
    "Elite",
    "Basic",
  ];
  const validateSlotDuration = (value) => {
    const regex = /^(?:(\d+)\s*Mins)?\s*(?:(\d+)\s*Sec)?$/;
    return regex.test(value);
  };

  const validateTiming = (value) => {
    const regex = /^(1[0-2]|[1-9]) (AM|PM)\s*-\s*(1[0-2]|[1-9]) (AM|PM)$/;
    return regex.test(value);
  };

  const validateLoopTime = (value) => {
    const regex = /^(?:(\d+)\s*Mins)?\s*(?:(\d+)\s*Sec)?$/;
    return regex.test(value);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validation checks
    if (name === "slotDuration") {
      setErrors({
        ...errors,
        slotDuration: validateSlotDuration(value)
          ? ""
          : "Invalid slot duration",
      });
    }
    if (name === "timing") {
      setErrors({
        ...errors,
        timing: validateTiming(value)
          ? ""
          : "Invalid timing format , Ex : (6 AM - 12 AM)",
      });
    }
    if (name === "loopTime") {
      setErrors({
        ...errors,
        loopTime: validateLoopTime(value) ? "" : "Invalid loop time",
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.adType) newErrors.adType = "Ad Type is required";
    if (!formData.planName) newErrors.planName = "Plan Name is required";
    if (
      !formData.noOfRotationsPerDay ||
      formData.noOfRotationsPerDay.length > 4
    ) {
      newErrors.noOfRotationsPerDay =
        "No Of Rotations Per Day is required and must be 4 digits max";
    }
    if (
      !formData.slotDuration ||
      !validateSlotDuration(formData.slotDuration)
    ) {
      newErrors.slotDuration =
        "Slot Duration is required and must be in the correct format";
    }
    if (!formData.noOfChannels)
      newErrors.noOfChannels = "No Of Channels is required";
    if (!formData.timing || !validateTiming(formData.timing)) {
      newErrors.timing = "Timing is required and must be in the correct format";
    }
    if (!formData.loopTime || !validateSlotDuration(formData.loopTime)) {
      newErrors.loopTime =
        "Loop Time is required and must be in the correct format";
    }
    if (!formData.adFormat) newErrors.adFormat = "Ad Format is required";
    if (!formData.ratePerDay) newErrors.ratePerDay = "Rate Per Day is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  // validations-end

  // Edit Data
  useEffect(() => {
    if (activeData) {
      const FindData = data?.find((data) => data._id == activeData);
      setFormData({
        adType: FindData?.adType,
        planName: FindData?.planName,
        noOfRotationsPerDay: FindData?.noOfRotationsPerDay,
        slotDuration: FindData?.slotDuration,
        noOfChannels: FindData?.noOfChannels,
        timing: FindData?.timing,
        loopTime: FindData?.loopTime,
        adFormat: FindData?.adFormat,
        ratePerDay: FindData?.ratePerDay,
        gst: FindData?.gst,
        costonly: FindData?.costonly,
      });
    }
  }, [activeData]);

  const fetchData = async () => {
    const res = await getData(PLANROUTES.GETPLAN, null, accessToken);
    if (res) {
      setData(res.data);
    } else {
      console.log(res.message);
    }
  };

  const DeletePlan = async (id) => {
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      const res = await deleteData(
        PLANROUTES.DELETEPLAN(id),
        accessToken,
        dispatch
      );
      if (res) {
        setFormData({
          adType: "",
          planName: "",
          noOfRotationsPerDay: "",
          slotDuration: "",
          timing: "",
          loopTime: "",
          adFormat: "",
          ratePerDay: "",
          gst: false,
          costonly: false,
        });
        fetchData();
        handleCloseModal();
      } else {
        console.log(res.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async () => {
    if (validateForm()) {
      if (action === "Add") {
        const res = await postData(
          PLANROUTES.AddPLAN,
          formData,
          accessToken,
          dispatch
        );
        if (res) {
          console.log(res);
          setFormData({
            adType: "",
            planName: "",
            noOfRotationsPerDay: "",
            slotDuration: "",
            timing: "",
            loopTime: "",
            adFormat: "",
            ratePerDay: "",
            gst: false,
            costonly: false,
          });
          fetchData();
          handleCloseModal();
        }
      } else {
        const res = await putData(
          PLANROUTES.PUTPLAN(activeData),
          formData,
          accessToken,
          dispatch
        );
        if (res) {
          console.log(res);
          setFormData({
            adType: "",
            planName: "",
            noOfRotationsPerDay: "",
            slotDuration: "",
            timing: "",
            loopTime: "",
            adFormat: "",
            ratePerDay: "",
            gst: false,
            costonly: false,
          });
          fetchData();
          handleCloseModal();
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Plans</title>
      </Helmet>

      <ToastContainer />
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={openModal}
        TransitionComponent={SlideUpTransition}
        keepMounted
        onClose={handleCloseModal}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{action} Plan</DialogTitle>
        <DialogContent>
          <div className="d-flex justify-content-start flex-wrap">
            <div className="col-3 px-1 my-2">
              <TextField
                select
                label="Ad Type"
                name="adType"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                className="mb-0 mt-3"
                style={{ width: "100%" }}
                value={formData?.adType}
                onChange={handleInputChange}
              >
                {Type.map((row, index) => (
                  <MenuItem key={index} value={row}>
                    {row}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div className="col-3 px-1 my-2">
              <TextField
                select
                label="Plan Name"
                name="planName"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                className="mb-0 mt-3"
                style={{ width: "100%" }}
                value={formData?.planName}
                onChange={handleInputChange}
              >
                {planNames.map((row, index) => (
                  <MenuItem key={index} value={row}>
                    {row}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div className="col-3 px-1 my-2">
              <TextField
                label="No Of Rotations Per Day (Times)"
                name="noOfRotationsPerDay"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                className="mb-0 mt-3"
                style={{ width: "100%" }}
                type="number"
                value={formData?.noOfRotationsPerDay}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length <= 4) {
                    handleInputChange(e);
                  }
                }}
              />
            </div>

            <div className="col-3 px-1 my-2">
              <TextField
                label="Slot Duration (Sec / Mins)"
                name="slotDuration"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                className="mb-0 mt-3"
                style={{ width: "100%" }}
                value={formData.slotDuration}
                onChange={handleInputChange}
                error={!!errors.slotDuration}
                helperText={errors.slotDuration}
              />
            </div>

            <div className="col-3 px-1 my-2">
              <TextField
                select
                label="No Of Channels"
                name="noOfChannels"
                value={formData?.noOfChannels}
                onChange={handleChange}
                size="small"
                InputLabelProps={{ shrink: true }}
                className="mt-3"
                style={{ width: "100%" }}
              >
                <MenuItem value="3 Per Area">3 Per Area</MenuItem>
                <MenuItem value="4 Per Area">4 Per Area</MenuItem>
                <MenuItem value="5 Per Area">5 Per Area</MenuItem>
                <MenuItem value="6 Per Area">6 Per Area</MenuItem>
                <MenuItem value="7 Per Area">7 Per Area</MenuItem>
                <MenuItem value="Entire Channel">Entire Channel</MenuItem>
              </TextField>
            </div>

            <div className="col-3 px-1 my-2">
              <TextField
                label="Timing"
                name="timing"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                className="mb-0 mt-3"
                style={{ width: "100%" }}
                value={formData?.timing}
                onChange={handleInputChange}
                error={!!errors.timing}
                helperText={errors.timing}
              />
            </div>

            <div className="col-3 px-1 my-2">
              <TextField
                label="Loop Time (Sec / Mins)"
                name="loopTime"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                className="mb-0 mt-3"
                style={{ width: "100%" }}
                value={formData?.loopTime}
                onChange={handleInputChange}
                error={!!errors.loopTime}
                helperText={errors.loopTime}
              />
            </div>

            <div className="col-3 px-1 my-2">
              <TextField
                label="AD Format"
                name="adFormat"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                className="mb-0 mt-3"
                style={{ width: "100%" }}
                value={formData?.adFormat}
                onChange={handleInputChange}
              />
            </div>

            <div className="col-3 px-1 my-2">
              <TextField
                label="Rate Per Day (Rs)"
                name="ratePerDay"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                className="mb-0 mt-3"
                style={{ width: "100%" }}
                value={formData?.ratePerDay}
                onChange={handleInputChange}
              />
            </div>

            <div className="col-1 px-1 my-2">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData?.gst}
                    onChange={handleChange}
                    name="gst"
                    color="primary"
                  />
                }
                label="GST"
                className="mt-3 fs-12"
              />
            </div>

            <div className="col-8 px-1 my-2">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData?.costonly}
                    onChange={handleChange}
                    name="costonly"
                    color="primary"
                  />
                }
                label="Cost Only For Current Channel it may differ channel Incresed"
                className="mt-3 fs-12"
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            className="mx-3 btn-danger"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="btn-green"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Plans
          </Typography>

          <Button
            variant="contained"
            className="main-col-new"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => handleClickOpenModal("Add")}
          >
            Add Plans
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer className="h-350">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>S.No</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Plan</TableCell>
                    <TableCell>No of Rotation Per day</TableCell>
                    <TableCell>Slot Duration </TableCell>
                    <TableCell>No of Channels </TableCell>
                    <TableCell>Timing </TableCell>
                    <TableCell>Loop Time </TableCell>
                    <TableCell>Format</TableCell>
                    <TableCell>Rate Per Day(Exclu.GST)</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((data, index) => {
                    return (
                      <TableRow
                        key={index}
                        className="cursor-pointer table-hover"
                      >
                        <TableCell
                          onClick={() => handleClickOpenModal("Edit", data._id)}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          onClick={() => handleClickOpenModal("Edit", data._id)}
                        >
                          {data.adType}{" "}
                        </TableCell>
                        <TableCell
                          onClick={() => handleClickOpenModal("Edit", data._id)}
                        >
                          {data.planName}{" "}
                        </TableCell>
                        <TableCell
                          onClick={() => handleClickOpenModal("Edit", data._id)}
                        >
                          {data.noOfRotationsPerDay} Times
                        </TableCell>
                        <TableCell
                          onClick={() => handleClickOpenModal("Edit", data._id)}
                        >
                          {data.slotDuration}
                        </TableCell>
                        <TableCell
                          onClick={() => handleClickOpenModal("Edit", data._id)}
                        >
                          {data.noOfChannels}
                        </TableCell>
                        <TableCell
                          onClick={() => handleClickOpenModal("Edit", data._id)}
                        >
                          {data.timing}
                        </TableCell>
                        <TableCell
                          onClick={() => handleClickOpenModal("Edit", data._id)}
                        >
                          {data.loopTime}
                        </TableCell>
                        <TableCell
                          onClick={() => handleClickOpenModal("Edit", data._id)}
                        >
                          {data.adFormat}
                        </TableCell>
                        <TableCell
                          onClick={() => handleClickOpenModal("Edit", data._id)}
                        >
                          Rs.{data.ratePerDay}
                          {data.gst && "+ GST"}
                          <br />
                          {data.costonly && (
                            <span className="fs-12">
                              (Cost Only For Current Channel; it may differ if
                              the channel count increases)
                            </span>
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => DeletePlan(data._id)}
                          align="center"
                        >
                          <Iconify
                            icon="fluent:delete-32-regular"
                            className="text-danger"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {data?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={11} align="center">
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}

export default PlansDetails;
