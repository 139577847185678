import API_URL from "../utils/url";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setView } from "../redux/slices/LoaderSlice";
const getData = async (route, queryParamsArg, token, method = "GET") => {
  try {
    const URL_ROUTE = `${API_URL}${route}`;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    let url = URL_ROUTE;
    if (queryParamsArg) {
      const queryParams = new URLSearchParams(queryParamsArg).toString();
      url += `?${queryParams}`;
    }

    const options = {
      method: method,
      headers: headers,
    };

    const response = await fetch(url, options);
    const responseData = await response.json();
    if (responseData.error === "Token has expired") {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    if (response) {
      console.log(responseData.error);
    } else {
      toast.error(responseData.error, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    return responseData;
  } catch (error) {
    console.error(error);
    toast.error(error.message || "An error occurred", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    throw error;
  }
};
const postData = async (route, data, token, dispatch) => {
  try {
    dispatch(setView(true));
    const URL_ROUTE = `${API_URL}${route}`;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const response = await fetch(URL_ROUTE, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch");
    }
    const responseData = await response.json();
    if (responseData.error === "Token has expired") {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    if (response) {
      dispatch(setView(false));
      toast.success(responseData.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        transition: Slide,
      });
    }
    return responseData;
  } catch (error) {
    dispatch(setView(false));
    toast.error(error.message || "An error occurred", {
      position: toast.POSITION.BOTTOM_CENTER,
      transition: Slide,
    });
    console.error(error);
    throw error;
  }
};
const postFormData = async (route, data, token, dispatch) => {
  try {
    dispatch(setView(true));
    const URL_ROUTE = `${API_URL}${route}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await fetch(URL_ROUTE, {
      method: "POST",
      headers: headers,
      body: data,
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch");
    }
    const responseData = await response.json();
    if (responseData.error === "Token has expired") {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    if (response) {
      dispatch(setView(false));
      toast.success(responseData.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        transition: Slide,
      });
    }
    return responseData;
  } catch (error) {
    dispatch(setView(false));
    toast.error(error.message || "An error occurred", {
      position: toast.POSITION.BOTTOM_CENTER,
      transition: Slide,
    });
    console.error(error);
    throw error;
  }
};
const putData = async (route, data, token, dispatch) => {
  try {
    dispatch(setView(true));
    const URL_ROUTE = `${API_URL}${route}`;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const response = await fetch(URL_ROUTE, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (responseData.error === "Token has expired") {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }

    if (response) {
      dispatch(setView(false));
      toast.success(responseData.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    return responseData;
  } catch (error) {
    dispatch(setView(false));
    console.error(error);
    toast.error(error.message || "An error occurred", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    throw error;
  }
};
const putFormData = async (route, data, token, dispatch) => {
  try {
    dispatch(setView(true));
    const URL_ROUTE = `${API_URL}${route}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await fetch(URL_ROUTE, {
      method: "PUT",
      headers: headers,
      body: data,
    });
    const responseData = await response.json();
    if (responseData.error === "Token has expired") {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }

    if (response) {
      dispatch(setView(false));
      toast.success(responseData.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    return responseData;
  } catch (error) {
    dispatch(setView(false));
    console.error(error);
    toast.error(error.message || "An error occurred", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    throw error;
  }
};
const deleteData = async (route, token, dispatch) => {
  try {
    dispatch(setView(true));
    const URL_ROUTE = `${API_URL}${route}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await fetch(URL_ROUTE, {
      method: "DELETE",
      headers: headers,
    });
    const responseData = await response.json();
    if (responseData.error === "Token has expired") {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    if (response) {
      dispatch(setView(false));
      toast.success(responseData.message, {
        position: toast.POSITION.BOTTOM_CENTER, 
      });
    }
    return responseData;
  } catch (error) {
    dispatch(setView(false));
    console.error(error);
    toast.error(error.message || "An error occurred", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    throw error;
  }
};

export { getData, postData, postFormData, putData, deleteData, putFormData };
