import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ReusablePaginatedSelect from "../../components/select-pagination";
import {
  getData,
  patchData,
  postData,
  putData,
} from "../../webService/webService";
import USERSROUTES from "../../API_Routes/User/User_Routers";
// import TEAMROUTES from "../../API_Routes/Team/Team_Routers";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import LOCATIONROUTES from "../../API_Routes/Location/Location_Routers";
import TEAMROUTES from "../../API_Routes/Team/Team_Routers";
import Iconify from "../../components/iconify";
import { useDispatch } from "react-redux";

const initialFormData = {
  regionalHeadId: [],
  relationExecutiveId: [],
  fieldOfficerId: [],
  areaSalesManagerId: [],
  zoneId: "",
};

function CreateTeam({ editData, handleCloseEdit, selectedUserId, viewMode }) {
  const accessToken = sessionStorage.getItem("Token");
  const dispatch = useDispatch();
  const [teamData, setTeamData] = useState(initialFormData);
  const [FieldOfficer, setFieldOfficer] = useState([]);
  const [RegionalHead, setRegionalHead] = useState([]);
  const [RelationExecutive, setRelationExecutive] = useState([]);
  const [AreaSalesManager, setAreaSalesManager] = useState([]);

  const CreateTeamNew = async () => {
    console.log(teamData, "teamData");
    const isValid = Object.values(teamData).every((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      if (typeof value === "string") {
        return value.trim() !== "";
      }
      return value != null;
    });
    if (!isValid) {
      alert("All fields are required.");
      return;
    }

    if (editData) {
      putData(
        TEAMROUTES.PUTTEAM(selectedUserId),
        teamData,
        accessToken,
        dispatch
      )
        .then((res) => {
          setTeamData(initialFormData);
          handleCloseEdit();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      postData(TEAMROUTES.ADDTEAM, teamData, accessToken, dispatch)
        .then((res) => {
          setTeamData(initialFormData);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  const ClearData = () => {
    setTeamData(initialFormData);
  };

  // Fetch all locations
  const [allZone, setZone] = useState();
  const fetchZone = () => {
    getData(LOCATIONROUTES.GETLOCATION, null, accessToken)
      .then((res) => {
        setZone(res.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // Fetch all Role
  const fetchRoleData = () => {
    getData(TEAMROUTES.GETTEAMMEMBERS, null, accessToken)
      .then((res) => {
        res.forEach((item) => {
          switch (item.role) {
            case "Regional Head":
              setRegionalHead(item.members);
              break;
            case "Area Sales Manager":
              setAreaSalesManager(item.members);
              break;
            case "Relation Executive":
              setRelationExecutive(item.members);
              break;
            case "Field Officer":
              setFieldOfficer(item.members);
              break;
            default:
              break;
          }
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchRoleData();
    fetchZone();
  }, []);

  const handleChange = (event, role) => {
    const { value } = event.target;
    setTeamData((prevData) => ({
      ...prevData,
      [role]: value,
    }));
  };

  const handleZone = (event) => {
    setTeamData((prevData) => ({
      ...prevData,
      zoneId: event.target.value,
    }));
  };

  // const handleRemove = (id, role, event) => {
  //   event.stopPropagation(); // Prevent dropdown from opening when deleting
  //   setTeamData((prevData) => ({
  //     ...prevData,
  //     [role]: prevData[role].filter((itemId) => itemId !== id),
  //   }));
  // };

  const renderSelect = (label, value, handleChange, members, role) => (
    <FormControl fullWidth margin="normal">
      <InputLabel
        shrink
        id={`${role}-select-label`}
        style={{ background: "white", padding: "0px 10px" }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={`${role}-select-label`}
        id={`${role}-select`}
        multiple
        value={value}
        onChange={(e) => handleChange(e, role)}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((id) => {
              const selectedMember = members.find(
                (member) => member._id === id
              );
              return (
                <Chip
                  key={id}
                  label={`${selectedMember?.firstName} ${selectedMember?.lastName}`}
                  // deleteIcon={
                  //   <IconButton
                  //     onClick={(event) => handleRemove(id, role, event)}
                  //     size="small"
                  //   >
                  //     <Iconify icon="mdi:remove-bold" className="text-danger" />
                  //   </IconButton>
                  // }
                  // onDelete={(event) => handleRemove(id, role, event)}
                />
              );
            })}
          </Box>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200, // Set max height for the dropdown
            },
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#ccc", // Normal border color
            },
            "&:hover fieldset": {
              borderColor: "#007bff", // On hover
            },
            "&.Mui-focused fieldset": {
              borderColor: "#007bff", // On focus
            },
          },
          "& .MuiInputLabel-shrink": {
            color: "#007bff", // Label color when focused
          },
        }}
      >
        {members.map((member) => (
          <MenuItem key={member._id} value={member._id}>
            {member.firstName} {member.lastName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  useEffect(() => {
    if (editData) {
      setTeamData(editData);
    }
  }, [editData]);

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>
          {viewMode ? "View Team" : editData ? "Edit Team" : "Create Team"}
        </title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {viewMode ? "View Team" : editData ? "Edit Team" : "Create Team"}
          </Typography>
        </Stack>
        <Card className="p-3 h-100" style={{ height: "100vh" }}>
          <div className="d-flex flex-wrap">
            <div className="col-md-4 col-sm-6 col-12 px-1">
              <TextField
                select
                className="mt-3"
                label="Select Zone"
                value={teamData.zoneId}
                onChange={handleZone}
                fullWidth
              >
                {allZone?.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {`${item.district} - ${item.state} - ${item.zoneName}`}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-md-4 col-sm-6 col-12 px-1">
              {renderSelect(
                "Select Regional Head",
                teamData.regionalHeadId,
                handleChange,
                RegionalHead,
                "regionalHeadId"
              )}
            </div>
            <div className="col-md-4 col-sm-6 col-12 px-1">
              {renderSelect(
                "Select Area Sales Manager",
                teamData.areaSalesManagerId,
                handleChange,
                AreaSalesManager,
                "areaSalesManagerId"
              )}
            </div>
          </div>
          <div className="d-flex flex-wrap mt-3">
            <div className="col-md-4 col-sm-6 col-12 px-1">
              {renderSelect(
                "Select Relation Executive",
                teamData.relationExecutiveId,
                handleChange,
                RelationExecutive,
                "relationExecutiveId"
              )}
            </div>
            <div className="col-md-4 col-sm-6 col-12 px-1">
              {renderSelect(
                "Select Field Officer",
                teamData.fieldOfficerId,
                handleChange,
                FieldOfficer,
                "fieldOfficerId"
              )}
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            {editData && (
              <Button
                variant="contained"
                className="btn-danger"
                onClick={handleCloseEdit}
              >
                Close
              </Button>
            )}
            {!editData && (
              <Button
                variant="contained"
                className="btn-danger"
                onClick={ClearData}
              >
                Clear
              </Button>
            )}

            {!viewMode && (
              <Button
                onClick={CreateTeamNew}
                variant="contained"
                className="btn-green"
              >
                Submit
              </Button>
            )}
          </div>
        </Card>
      </Container>
    </>
  );
}

export default CreateTeam;
