import { Card, Container, Stack, Typography } from "@mui/material";
import React from "react";
import "../Notification/Notification.css";
import Iconify from "../../components/iconify";

function Notification() {
  const notificationData = [
    {
      content:
        "Telecaller1 updated client details 1 ddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlfjddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj",
      date: "02-09-2024",
      time: "12:15pm",
    },
    {
      content:
        "Telecaller1 updated client details 1 ddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlfjddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj",
      date: "02-09-2024",
      time: "12:15pm",
    },
    {
      content:
        "Telecaller1 updated client details 1 ddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlfjddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj",
      date: "02-09-2024",
      time: "12:15pm",
    },
    {
      content:
        "Telecaller1 updated client details 1 ddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlfjddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj",
      date: "02-09-2024",
      time: "12:15pm",
    },
    {
      content:
        "Telecaller1 updated client details 1 ddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlfjddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj",
      date: "02-09-2024",
      time: "12:15pm",
    },
    {
      content:
        "Telecaller1 updated client details 1 ddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlfjddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj",
      date: "02-09-2024",
      time: "12:15pm",
    },
    {
      content:
        "Telecaller1 updated client details 1 ddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj ddfkaljadsfklasdf dlkfjasdlfjddfkaljadsfklasdf dlkfjasdlkfjddfkaljadsfklasdf dlkfjasdlkfj",
      date: "02-09-2024",
      time: "12:15pm",
    },
  ];

  return (
    <>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            All Notifications
          </Typography>
        </Stack>

        <Card className="p-3">
          {notificationData &&
            notificationData.map((row, index) => {
              const notificationClass = `notification-${index % 3}`; // Mod 6 to cycle through classes
              return (
                <div
                  className={`notificationstyle ${notificationClass} d-flex justify-content-between`}
                  key={index}
                >
                  <p style={{ maxWidth: "80%" }}
                      className="mr-3 mb-0 mt-0 notificationwrap">
                    <p
                      style={{ maxWidth: "80%" }}
                      className="mr-3 mb-0 mt-0 notificationwrap"
                    >
                      {row.content}
                    </p>
                    <p className="mr-3 mb-0 mt-0 fs-14">
                      {row.time} - {row.date}
                    </p>
                  </p>
                  <Iconify icon="iwwa:delete" />
                </div>
              );
            })}
        </Card>
      </Container>
    </>
  );
}

export default Notification;
