import { Helmet } from "react-helmet-async";
import { faker } from "@faker-js/faker";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container } from "@mui/material";
// sections
import {
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
} from "../sections/@dashboard/app";
import User from "../components/logo/admin.webp";
import Welcomebox from "../components/Dashboard/welcomebox";
import StatusChart from "../components/Dashboard/StatusChart";
import UpcomingDeadline from "../components/Dashboard/UpcomingDeadline";
import TopTeamMembers from "../components/Dashboard/TopTeamMembers";
import WelcomeSwiper from "../components/Dashboard/welcomebox";

// ----------------------------------------------------------------------

export default function DashboardAppPage({ onLogout }) {
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Container maxWidth="xl">
        <div className="w-100 welcome-sec mb-3 p-3 d-flex flex-wrap justify-content-between">
          <div className="col-md-10 col-12">
            <h2 className="text-white-light fw-bold">Welcome Back</h2>
            <h2 className="text-white-light fw-bold mb-4">{isAuthenticated}</h2>
            <WelcomeSwiper />
          </div>
          <div className="col-md-2 col-12 d-flex justify-content-end">
            <img src={User} className="d-md-block d-none" alt="profile-image" />
          </div>
        </div>
        <div className="d-flex flex-wrap-reverse my-3">
          <div className="col-md-8  col-12 pe-md-2 py-3">
            <UpcomingDeadline />
          </div>
          <div className="col-md-4 col-12 ps-md-2 py-3">
            <TopTeamMembers />
          </div>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <StatusChart />
          </Grid>
          {/* <Grid item xs={12} md={12} lg={12}>
            <AppWebsiteVisits
              title="Conversion Reports"
              subheader="(+43%) than last year"
              chartLabels={[
                "01/01/2003",
                "02/01/2003",
                "03/01/2003",
                "04/01/2003",
                "05/01/2003",
                "06/01/2003",
                "07/01/2003",
                "08/01/2003",
                "09/01/2003",
                "10/01/2003",
                "11/01/2003",
              ]}
              chartData={[
                {
                  name: "Leads",
                  type: "column",
                  fill: "solid",
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: "Opportunities",
                  type: "area",
                  fill: "gradient",
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: "Won",
                  type: "line",
                  fill: "solid",
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
