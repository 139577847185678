import PropTypes from "prop-types";
import { useState } from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
// @mui
import { Box, List, ListItemText, Collapse } from "@mui/material";
// import { ExpandLess, Expand More } from '@mui/icons-material';
//
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import Iconify from "../iconify";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Box {...other}>
      <List disablePadding sx={{ py: 1 }} style={{ paddingLeft: "20px" }}>
        {data.map((item, index) => (
          <NavItem
            key={item.title}
            item={item}
            open={openIndex === index}
            onClick={() => handleClick(index)}
          />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool,
  onClick: PropTypes.func,
};

function NavItem({ item, open, onClick }) {
  const { title, path, icon, info, children } = item;

  if (children) {
    return (
      <>
        <StyledNavItem
          onClick={onClick}
          sx={{ cursor: "pointer" }}
          // className={open ? "Active-title position-relative" : ""}
        >
          {/* {open && (
            <>
              <span className="shape1"></span>
              <span className="shape2"></span>
            </>
          )} */}
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
          <ListItemText disableTypography primary={title} />
          {open ? (
            <Iconify icon="mingcute:down-line" className="me-3" />
          ) : (
            <Iconify icon="mingcute:right-line" className="me-3" />
          )}
        </StyledNavItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child) => (
              <StyledNavItem
                key={child.title}
                component={RouterLink}
                to={child.path}
                sx={{
                  pl: 4,
                  "&.active": {
                    color: "black",
                    bgcolor: "var(--main-color-100)",
                    fontWeight: "bolder",
                    borderRadius: "30px 0px 0px 30px",
                    position: "relative",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: "-30px",
                      zIndex: 9,
                      insetInlineEnd: "0px",
                      bottom: "0",
                      height: "30px",
                      borderTopColor: "transparent",
                      borderLeftColor: "transparent",
                      borderBottom: "transparent",
                      borderStartStartRadius: "0",
                      borderStartEndRadius: "0",
                      borderEndEndRadius: "48px",
                      borderEndStartRadius: "0",
                      borderInlineEnd: "20px solid #7e67b9",
                    },
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      top: "48px",
                      zIndex: 0,
                      insetInlineEnd: "0px",
                      bottom: 0,
                      height: "30px",
                      borderTopColor: "transparent",
                      borderLeftColor: "transparent",
                      borderBottom: "transparent",
                      borderStartStartRadius: 0,
                      borderStartEndRadius: "48px",
                      borderEndEndRadius: 0,
                      borderEndStartRadius: 0,
                      borderInlineEnd: "20px solid #7e67b9",
                    },
                  },
                }}
              >
                {window.location.pathname === child.path && (
                  <>
                    <span className="shape1"></span>
                    <span className="shape2"></span>
                  </>
                )}
                <StyledNavItemIcon>
                  {child.icon && child.icon}
                </StyledNavItemIcon>
                <ListItemText disableTypography primary={child.title} />
              </StyledNavItem>
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      className="pe-0"
      sx={{
        "&.active": {
          color: "black",
          bgcolor: "var(--main-color-100)",
          fontWeight: "bolder",
          borderRadius: "30px 0px 0px 30px",
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: "-30px",
            zIndex: 9,
            insetInlineEnd: "0px",
            bottom: "0",
            height: "30px",
            borderTopColor: "transparent",
            borderLeftColor: "transparent",
            borderBottom: "transparent",
            borderStartStartRadius: "0",
            borderStartEndRadius: "0",
            borderEndEndRadius: "48px",
            borderEndStartRadius: "0",
            borderInlineEnd: "20px solid #7e67b9",
          },
          "&::after": {
            content: '""',
            position: "absolute",
            top: "48px",
            zIndex: 0,
            insetInlineEnd: "0px",
            bottom: 0,
            height: "30px",
            borderTopColor: "transparent",
            borderLeftColor: "transparent",
            borderBottom: "transparent",
            borderStartStartRadius: 0,
            borderStartEndRadius: "48px",
            borderEndEndRadius: 0,
            borderEndStartRadius: 0,
            borderInlineEnd: "20px solid #7e67b9",
          },
        },
      }}
    >
      {window.location.pathname === path && (
        <>
          <span className="shape1"></span>
          <span className="shape2"></span>
        </>
      )}
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </StyledNavItem>
  );
}
