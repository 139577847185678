import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import { Autoplay, EffectFade } from "swiper/modules";
import { getData } from "../../webService/webService";

const SwiperLogin = () => {
  const [Data, setData] = useState([]);

  const fetchdata = () => {
    getData("banners", null, null)
      .then((res) => {
        setData(res.data || []);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchdata();
  }, []);

  return (
    <Swiper
      modules={[EffectFade, Autoplay]}
      effect="fade"
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      speed={1000}
      loop={true}
      className="mySwiper w-100 h-100"
    >
      {Data.length > 0 ? (
        Data.map((item, index) => (
          <SwiperSlide key={index}>
            <img
              src={`https://api.aymrk.digitaltriumph.co.in/${item.bannerImages[0]}`}
              alt={item.bannerImages[0]}
              className="w-100 h-100"
            />
          </SwiperSlide>
        ))
      ) : (
        <SwiperSlide>
          <div className="w-100 h-100 flex justify-content-center align-items-center">
            <p>Welcome AYMRK</p>
          </div>
        </SwiperSlide>
      )}
    </Swiper>
  );
};

export default SwiperLogin;
