import { Card } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";

const StatusChart = () => {
  // Sample API response
  const apiresponse = [
    {
      Pending: "2000",
      "Waiting for Executive": "200",
      "Waiting for Telecaller": "3000",
      "Waiting for Admin Approval": "24000",
    },
  ];

  // Extract categories (x-axis) and values (y-axis)
  const categories = Object.keys(apiresponse[0]);
  const dataValues = Object.values(apiresponse[0]).map(Number);

  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
      distributed: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "all",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      //   title: {
      //     text: "Values",
      //   },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        gradientToColors: ["rgba(132,108,249,1)"],
        stops: [7, 80],
      },
    },
    // title: {
    //   text: "Distributed Column Chart",
    //   align: "center",
    // },
  };

  const chartSeries = [
    {
      name: "Count",
      data: dataValues,
    },
  ];

  return (
    <Card className="p-2">
      <ReactApexChart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={350}
      />
    </Card>
  );
};

export default StatusChart;
