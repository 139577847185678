import { Card } from "@mui/material";
import React from "react";
import styled from "styled-components";

const Img = styled.img`
  border-radius: 50%;
  height: 70px;
  width: 70px;
  object-fit: cover;
  @media (max-width: 900px) {
    height: 50px;
    width: 50px;
  }
`;
const TopTeamMembers = () => {
  const Data = [
    {
      image:
        "https://t3.ftcdn.net/jpg/09/14/03/72/240_F_914037280_vjHZmxPgtpC661BSPVA8BOef3biswo99.jpg",
      count1: "100",
      count2: "200",
      role: "Tele celler",
      background: "#22c55e1a",
    },
    {
      image:
        "https://t4.ftcdn.net/jpg/07/28/78/53/240_F_728785396_muNh6GKN3XdVTePE7vGCxPcXgpUBDdaA.jpg",
      count1: "50",
      count2: "100",
      role: "Executive",
      background: "#c59d213d",
    },
    {
      image:
        "https://e0.pxfuel.com/wallpapers/900/942/desktop-wallpaper-cartoon-cartoon-new-cartoon-boy-cartoon-letest-cartoon-cute-cartoon-cute-bay-cartoon-kartoon-thumbnail.jpg",
      count1: "20",
      count2: "50",
      role: "Tele celler",
      background: "#3be0f64f",
    },
  ];
  return (
    <Card className="p-3">
      <div>
        <h5 className="mb-4">Top Contributer</h5>
        {Data.map((item, index) => (
          <Card
            className="w-100 d-flex my-3 p-2 align-items-center"
            style={{ backgroundColor: item.background }}
          >
            <div className="col-3 d-flex justify-content-center">
              <Img src={item.image} />
            </div>
            <div className="col-9">
              <p className="fs-14 mb-0 py-2">{item.role}</p>
              <h6 className="fs-12">Total Lead To Customer : {item.count1}</h6>
              <h6 className="fs-12">Total Lead To Client : {item.count2}</h6>
            </div>
          </Card>
        ))}
      </div>
    </Card>
  );
};

export default TopTeamMembers;
