import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postData } from "../../../webService/webService";
// @mui
import { Stack, IconButton, InputAdornment, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";
import { useDispatch } from "react-redux";
import USERSROUTES from "src/API_Routes/User/User_Routers";
// ----------------------------------------------------------------------

export default function LoginForm({ onLogin }) {
  const dispatch = useDispatch();
  const [mail, setMail] = useState("");
  const [pwd, setPwd] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    const LoginParams = {
      mail: mail.trim(),
      password: pwd,
    };
    if (mail === "" && pwd === "") {
      toast.error("All The Filed's Are Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      sessionStorage.setItem("UserMail", mail);
      // onLogin("Relation Executive");
      // sessionStorage.setItem("Role", "Relation Executive");
      // navigate("/relation-executive/dashboard", { replace: true });
      postData(USERSROUTES.LOGIN, LoginParams, null, dispatch)
        .then((res) => {
          sessionStorage.setItem("Token", res?.accessToken);
          sessionStorage.setItem("Role", res?.role);
          onLogin(res?.role);
          switch (res?.role) {
            case "Admin":
              navigate("/admin/dashboard", { replace: true });
              break;
            case "Sub Admin":
              navigate("/sub-admin/dashboard", { replace: true });
              break;
            case "Regional Head":
              navigate("/regional-head/dashboard", { replace: true });
              break;
            case "Area Sales Manager":
              navigate("/area-sales-manager/dashboard", { replace: true });
              break;
            case "Relation Executive":
              navigate("/relation-executive/dashboard", { replace: true });
              break;
            default:
              console.log("No Routes");
              break;
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <Stack spacing={3}>
        <h4 className="text-center fw-bold my-0">AYMRK</h4>
        <h6 className="text-center fw-bold fs-12 my-2">
          MAKE YOUR BRAND IN MARKED PLACE
        </h6>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleClick();
          }}
        >
          <TextField
            name="email"
            label="Email address"
            value={mail}
            size="small"
            className="w-100 mb-4"
            variant="standard"
            onChange={(e) => setMail(e.target.value)}
            autoComplete="off"
            required
          />
          <TextField
            autoComplete="off"
            size="small"
            name="password"
            label="Password"
            variant="standard"
            className="w-100 mb-2"
            required
            type={showPassword ? "text" : "password"}
            value={pwd}
            onChange={(e) => setPwd(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{ my: 5 }}
          >
            Login
          </LoadingButton>
        </form>
      </Stack>
    </>
  );
}
