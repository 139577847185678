import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { useEffect, useState } from "react";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
// components
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead } from "../sections/@dashboard/user";
// mock
// import USERLIST from "../_mock/user";
import {
  deleteData,
  getData,
  putData,
  postData,
  postFormData,
  putFormData,
} from "../webService/webService";
import USERSROUTES from "../API_Routes/User/User_Routers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showConfirmationDialog } from "../utils/sweetalertUtils";
import { setView } from "../redux/slices/LoaderSlice";
import { useDispatch } from "react-redux";
import PaginationComponent from "../components/Pagination";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function UserPage({ Role, Currentstatus }) {
  const TABLE_HEAD = [
    { id: "Name", label: "Name", alignRight: false },
    { id: "Role", label: "Role", alignRight: false },
    { id: "Email", label: "Email", alignRight: false },
    ...(Role === "Agent" && Currentstatus === "waiting for approval"
      ? [{ id: "Approve Status", label: "Approve Status", alignRight: false }]
      : []),
    { id: "Action", label: "Action", alignRight: false },
  ];

  console.log(Role, "Role");
  console.log(Currentstatus, "status");
  const dispatch = useDispatch();
  const formattedRole = Role.split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
  const [USERLIST, setUSERLIST] = useState(null);
  const accessToken = sessionStorage.getItem("Token");
  const [open, setOpen] = useState(null);
  const [active, setActive] = useState();
  const [selectedUserId, setSelectedUserId] = useState(null);
  console.log(USERLIST, "USERLIST123");

  // Table Conditions
  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedUserId(id);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const [totalcount, setTotalCount] = useState(0);
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    fetchdata(0, event.target.value);

    setCurrentPage(1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage <= totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Add User
  const [adduseropen, setadduserOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mail: "",
    password: "",
    contact: "",
    addressProofId: "",
    doorNo: "",
    street: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
    msTeamsUrl: "",
    role: Role,
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let tempErrors = {};
    let valid = true;

    if (!formData.firstName) {
      tempErrors.firstName = "First Name is required";
      valid = false;
    }

    if (!formData.lastName) {
      tempErrors.lastName = "Last Name is required";
      valid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.mail) {
      tempErrors.mail = "Mail is required";
      valid = false;
    } else if (!emailRegex.test(formData.mail)) {
      tempErrors.mail = "Enter a valid mail";
      valid = false;
    }

    const passwordRegex =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    if (!formData.password && active !== "Edit") {
      tempErrors.password = "Password is required";
      valid = false;
    } else if (!passwordRegex.test(formData.password) && active !== "Edit") {
      tempErrors.password =
        "Password must be 6-16 characters and include at least one number and one special character";
      valid = false;
    }

    if (!formData.contact) {
      tempErrors.contact = "Phone Number is required";
      valid = false;
    } else if (!/^\d{10}$/.test(formData.contact)) {
      tempErrors.contact = "Phone Number must be exactly 10 digits";
      valid = false;
    }

    if (!formData.doorNo) {
      tempErrors.doorNo = "Door No is required";
      valid = false;
    }

    if (!formData.street) {
      tempErrors.street = "Street is required";
      valid = false;
    }

    if (!formData.pincode) {
      tempErrors.pincode = "Pincode is required";
      valid = false;
    } else if (!/^\d{6}$/.test(formData.pincode)) {
      tempErrors.pincode = "Pincode must be exactly 6 digits";
      valid = false;
    }

    if (!formData.city) {
      tempErrors.city = "City is required";
      valid = false;
    }

    if (!formData.msTeamsUrl) {
      tempErrors.msTeamsUrl = "Teams Id is required";
      valid = false;
    }

    if (!formData.state) {
      tempErrors.state = "State is required";
      valid = false;
    }

    if (!formData.country) {
      tempErrors.country = "Country is required";
      valid = false;
    }

    setErrors(tempErrors);
    return valid;
  };

  const handleadduserOpen = (data) => {
    setActive(data);
    setadduserOpen(true);
    if (data === "Add") {
      setFormData({
        firstName: "",
        lastName: "",
        mail: "",
        password: "",
        contact: "",
        addressProofId: "",
        doorNo: "",
        street: "",
        pincode: "",
        city: "",
        state: "",
        country: "",
        msTeamsUrl: "",
        role: Role,
      });
    }
  };

  const handleadduserClose = () => setadduserOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      var formDataPost = new FormData();
      formDataPost.append("firstName", formData.firstName);
      formDataPost.append("lastName", formData.lastName);
      formDataPost.append("mail", formData.mail);
      formDataPost.append("password", formData.password);
      formDataPost.append("contact", formData.contact);
      formDataPost.append("addressProofId", formData.addressProofId);
      formDataPost.append("doorNo", formData.doorNo);
      formDataPost.append("street", formData.street);
      formDataPost.append("pincode", formData.pincode);
      formDataPost.append("city", formData.city);
      formDataPost.append("state", formData.state);
      formDataPost.append("country", formData.country);
      formDataPost.append("msTeamsUrl", formData.msTeamsUrl);
      formDataPost.append("role", Role);
      if (active === "Add") {
        postFormData(USERSROUTES.AddUSER, formDataPost, accessToken, dispatch)
          .then((res) => {
            console.log(res);
            setFormData({
              firstName: "",
              lastName: "",
              mail: "",
              password: "",
              contact: "",
              addressProofId: "",
              doorNo: "",
              street: "",
              pincode: "",
              city: "",
              state: "",
              country: "",
              msTeamsUrl: "",
              role: Role,
            });
            setLimit(5);
            fetchdata(0, 5);
            handleadduserClose();
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        putFormData(
          USERSROUTES.PATCHUSER(Role, selectedUserId),
          formDataPost,
          accessToken,
          dispatch
        )
          .then((res) => {
            const updatedData = USERLIST.map((item) =>
              item._id === selectedUserId ? { ...item, ...res.data } : item
            );
            setUSERLIST(updatedData);
            setOpen(null);
            setSelectedUserId(null);
            setFormData({
              firstName: "",
              lastName: "",
              mail: "",
              password: "",
              contact: "",
              addressProofId: "",
              doorNo: "",
              street: "",
              pincode: "",
              city: "",
              state: "",
              country: "",
              msTeamsUrl: "",
              role: Role,
            });
            handleadduserClose();
          })
          .catch((error) => {
            setOpen(null);
            console.error("Error:", error);
            toast.error("An error occurred. Please try again later.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
        handleadduserClose();
      }
    }
  };

  const fetchdata = (skip, limit) => {
    const params = {
      role: Role,
      skip: skip,
      limit: limit,
    };
    getData(USERSROUTES.GETUSER, params, accessToken)
      .then((res) => {
        if (res.status === 404) {
          setUSERLIST(null);
          setTotalPages(1);
        } else {
          setUSERLIST(res.data);
          setTotalPages(res.totalPages);
          setTotalCount(res.total);
        }
        console.log(res);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    if (Role) {
      setCurrentPage(1);
      fetchdata(0, 5);
      setLimit(5);
    }
  }, [Role]);

  useEffect(() => {
    if (currentPage !== 1) {
      const skip = (currentPage - 1) * limit;
      fetchdata(skip, limit);
    }
  }, [currentPage]);

  const DeleteId = async (val) => {
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      deleteData(USERSROUTES.DELETEUSER(Role, val), accessToken, dispatch)
        .then((res) => {
          const skip = (currentPage - 1) * limit;
          fetchdata(skip, limit);
          setOpen(null);
        })
        .catch((error) => {
          setOpen(null);
          console.error("Error:", error);
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  useEffect(() => {
    if (selectedUserId) {
      const EditUserData = USERLIST.find((item) => item._id === selectedUserId);
      setFormData({
        firstName: EditUserData.firstName,
        lastName: EditUserData.lastName,
        mail: EditUserData.mail,
        password: EditUserData.password,
        contact: EditUserData.contact,
        addressProofId: EditUserData.addressProofId,
        doorNo: EditUserData.doorNo,
        street: EditUserData.street,
        pincode: EditUserData.pincode,
        city: EditUserData.city,
        state: EditUserData.state,
        country: EditUserData.country,
        msTeamsUrl: EditUserData.msTeamsUrl,
        role: Role,
      });
    }
  }, [selectedUserId]);

  useEffect(() => {
    setFormData({
      firstName: "",
      lastName: "",
      mail: "",
      password: "",
      contact: "",
      addressProofId: "",
      doorNo: "",
      street: "",
      pincode: "",
      city: "",
      state: "",
      country: "",
      msTeamsUrl: "",
      role: Role,
    });
  }, [Role]);

  return (
    <>
      <Helmet>
        <title>{Role}</title>
      </Helmet>

      <ToastContainer />

      <Modal open={adduseropen} onClose={handleadduserClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
          }}
        >
          <h5>
            {active} {formattedRole}
          </h5>
          <div className="d-flex flex-wrap">
            <div className="col-md-4 col-sm-6 col-12 px-1">
              <TextField
                fullWidth
                label="First Name *"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.firstName}
                helperText={errors.firstName}
              />
            </div>
            <div className="col-md-4 col-sm-6 col-12 px-1">
              <TextField
                fullWidth
                label="Last Name *"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.lastName}
                helperText={errors.lastName}
              />
            </div>
            <div className="col-md-4 col-sm-6 col-12 px-1">
              <TextField
                fullWidth
                label="Email *"
                name="mail"
                value={formData.mail}
                onChange={handleChange}
                margin="normal"
                autoComplete="off"
                size="small"
                error={!!errors.mail}
                helperText={errors.mail}
              />
            </div>
            <div className="col-md-4 col-sm-6 col-12 px-1">
              <TextField
                fullWidth
                label="Password *"
                name="password"
                type="text"
                value={formData.password}
                onChange={handleChange}
                margin="normal"
                autoComplete="off"
                size="small"
                error={!!errors.password}
                helperText={errors.password}
              />
            </div>
            <div className="col-md-4 col-sm-6 col-12 px-1">
              <TextField
                fullWidth
                type="number"
                label="Phone Number *"
                name="contact"
                value={formData.contact}
                onChange={handleChange}
                // onChange={(e) => {
                //   if (e.target.value.length <= 10) {
                //     handleChange(e);
                //   }
                // }}
                margin="normal"
                size="small"
                error={!!errors.contact}
                helperText={errors.contact}
              />
            </div>
            <div className="col-md-4 col-sm-6 col-12 px-1">
              <TextField
                fullWidth
                type="number"
                label="Door No *"
                name="doorNo"
                value={formData.doorNo}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.doorNo}
                helperText={errors.doorNo}
              />
            </div>
            <div className="col-md-4 col-sm-6 col-12 px-1">
              <TextField
                fullWidth
                type="text"
                label="Street *"
                name="street"
                value={formData.street}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.street}
                helperText={errors.street}
              />
            </div>
            <div className="col-md-4 col-sm-6 col-12 px-1">
              <TextField
                fullWidth
                type="number"
                label="Pincode *"
                name="pincode"
                value={formData.pincode}
                onChange={(e) => {
                  if (e.target.value.length <= 6) {
                    handleChange(e);
                  }
                }}
                margin="normal"
                size="small"
                error={!!errors.pincode}
                helperText={errors.pincode}
              />
            </div>
            <div className="col-md-4 col-sm-6 col-12 px-1">
              <TextField
                fullWidth
                type="text"
                label="City *"
                name="city"
                value={formData.city}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.city}
                helperText={errors.city}
              />
            </div>
            <div className="col-md-4 col-sm-6 col-12 px-1">
              <TextField
                fullWidth
                type="text"
                label="Teams URL *"
                name="msTeamsUrl"
                value={formData.msTeamsUrl}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.msTeamsUrl}
                helperText={errors.msTeamsUrl}
              />
            </div>
            <div className="col-md-4 col-sm-6 col-12 px-1">
              <TextField
                fullWidth
                type="text"
                label="State *"
                name="state"
                value={formData.state}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.state}
                helperText={errors.state}
              />
            </div>
            <div className="col-md-4 col-sm-6 col-12 px-1">
              <TextField
                fullWidth
                type="text"
                label="Country *"
                name="country"
                value={formData.country}
                onChange={handleChange}
                margin="normal"
                size="small"
                error={!!errors.country}
                helperText={errors.country}
              />
            </div>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Button
              variant="contained"
              color="error"
              className="mx-3 btn-danger"
              onClick={handleadduserClose}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="btn-green"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {formattedRole}
          </Typography>
          {Currentstatus !== "waiting for approval" && (
            <Button
              variant="contained"
              className="main-col-new"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => handleadduserOpen("Add")}
            >
              Add {formattedRole}
            </Button>
          )}
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }} className="h-350">
              <Table stickyHeader>
                <UserListHead
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST?.length}
                />
                {USERLIST === null ? (
                  <TableBody>
                    <TableRow className="w-100 m-3">
                      <TableCell colSpan={TABLE_HEAD.length}>No Data</TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {USERLIST?.map((row) => {
                      const { _id, firstName, lastName, role, mail } = row;
                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="subtitle2"
                                noWrap
                                style={{ marginLeft: "10px" }}
                              >
                                {firstName} {lastName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{role}</TableCell>
                          <TableCell align="left">{mail}</TableCell>
                          <TableCell align="left">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => handleOpenMenu(event, _id)}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <PaginationComponent
            name={Role}
            totalcount={totalcount}
            limit={limit}
            handleLimitChange={handleLimitChange}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            currentPage={currentPage}
            totalPages={totalPages}
          />
          {/* <div className="d-flex flex-wrap justify-content-end m-3">
            <FormControl variant="outlined" size="small">
              <InputLabel id="limit-label">Limit</InputLabel>
              <Select
                labelId="limit-label"
                id="limit-select"
                value={limit}
                onChange={handleLimitChange}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </FormControl>
            <Button onClick={handlePrevPage} disabled={currentPage === 1}>
              <Iconify icon={"grommet-icons:previous"} />
            </Button>
            <Button onClick={handleNextPage} disabled={totalPages === 1}>
              <Iconify icon={"grommet-icons:next"} />
            </Button>
          </div> */}
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleadduserOpen("Edit");
          }}
        >
          <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem
          sx={{ color: "error.main" }}
          onClick={() => {
            DeleteId(selectedUserId);
          }}
        >
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
