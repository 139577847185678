import {
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Iconify from "../../components/iconify/Iconify";
import Scrollbar from "../../components/scrollbar/Scrollbar";
import AddClientLead from "../Leads/AddClientLead";

function AllClients() {
  function generateRandomData() {
    const names = [
      "John Doe",
      "Jane Smith",
      "Alice Johnson",
      "Bob Brown",
      "Charlie Davis",
      "Emily White",
      "Frank Harris",
      "Grace Lee",
      "Henry Walker",
      "Isabella King",
    ];
    const zones = ["East Zone", "West Zone", "North Zone", "South Zone"];
    const localities = [
      "New York",
      "Los Angeles",
      "Chicago",
      "Houston",
      "Phoenix",
      "Philadelphia",
      "San Antonio",
      "San Diego",
      "Dallas",
      "San Jose",
    ];
    const pincodes = [
      "10001",
      "90001",
      "60601",
      "77001",
      "85001",
      "19101",
      "78201",
      "92101",
      "75201",
      "95101",
    ];
    const screenSizes = ["720p", "1080p", "1440p", "4K"];

    const data = [];

    for (let i = 1; i <= 20; i++) {
      const randomName = names[Math.floor(Math.random() * names.length)];
      const randomZone = zones[Math.floor(Math.random() * zones.length)];
      const randomLocality =
        localities[Math.floor(Math.random() * localities.length)];
      const randomPincode =
        pincodes[Math.floor(Math.random() * pincodes.length)];
      const randomScreenSize =
        screenSizes[Math.floor(Math.random() * screenSizes.length)];
      const randomNoOfScreens = Math.floor(Math.random() * 10) + 1; // Random number between 1 and 10

      data.push({
        id: i,
        name: randomName,
        zone: randomZone,
        locality: randomLocality,
        pincode: randomPincode,
        screenSize: randomScreenSize,
        noOfScreens: randomNoOfScreens,
      });
    }

    return data;
  }

  const randomData = generateRandomData();
  console.log(randomData);

  const [addClientopen, setaddClientopen] = useState("");
  const [action, setAction] = useState();

  const handleaddClientopen = (data) => {
    setAction(data);
    setaddClientopen(data);
  };

  const handleaddClientClose = () => setaddClientopen("");

  return (
    <>
      {addClientopen === "" ? (
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              All Clients
            </Typography>

            <Button
              variant="contained"
              className="main-col-new"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => handleaddClientopen("Add")}
            >
              Add Client
            </Button>
          </Stack>

          <Card>
            <Scrollbar>
              <TableContainer className="h-350">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Zone</TableCell>
                      <TableCell>Locality</TableCell>
                      <TableCell>Pincode</TableCell>
                      <TableCell>Screen Size</TableCell>
                      <TableCell>No of Screen's</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {randomData?.map((data, index) => {
                      return (
                        <TableRow
                          key={index}
                          className="cursor-pointer table-hover"
                          onClick={() => handleaddClientopen("Edit")}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell>{data.name}</TableCell>
                          <TableCell>{data.zone}</TableCell>
                          <TableCell>{data.locality}</TableCell>
                          <TableCell>{data.pincode}</TableCell>
                          <TableCell>{data.screenSize}</TableCell>
                          <TableCell>{data.noOfScreens}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <div className="d-flex flex-wrap justify-content-end m-3">
              <FormControl variant="outlined" size="small">
                <InputLabel id="limit-label">Limit</InputLabel>
                <Select
                  labelId="limit-label"
                  id="limit-select"
                  value={3}
                  // onChange={handleLimitChange}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </FormControl>
              <Button
              // onClick={handlePrevPage} disabled={currentPage === 1}
              >
                <Iconify icon={"grommet-icons:previous"} />
              </Button>
              <Button
              // onClick={handleNextPage}
              // disabled={currentPage === totalPages}
              >
                <Iconify icon={"grommet-icons:next"} />
              </Button>
            </div>
          </Card>
        </Container>
      ) : addClientopen === "Add" || addClientopen === "Edit" ? (
        <AddClientLead
          handleaddClientClose={handleaddClientClose}
          action={action}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default AllClients;
