// @mui
import { styled } from "@mui/material/styles";
import { ListItemIcon, ListItemButton } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

// ----------------------------------------------------------------------

export const StyledNavItem = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  color: "#e3dcdc",
  borderRadius: "30px 0px 0px 30px",
  "&:hover": {
    backgroundColor: "unset",
    fontWeight: "bold",
    color: "white",
    TextDecoder: "none",
    // backgroundColor: "var(--main-color-200)",
    // color: "var(--black-color)",
    // borderRadius: "30px 0px 0px 30px",
    // zIndex: "9999",
  },
}));

export const StyledNavItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
