import { Navigate, Routes, Route, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
//
import UserPage from "./pages/UserPage";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import DashboardAppPage from "./pages/DashboardAppPage";
import CustomerLeads from "./pages/Leads/CustomerLeads";
import ClientLeads from "./pages/Leads/ClientLeads";
import Notification from "./pages/Notification/Notification";
import AllClients from "./pages/Clients/AllClients";
import AllCustomers from "./pages/Customers/AllCustomers";
import PlansDetails from "./pages/Plans/PlansDetails";
import Setting from "./pages/Settings/Setting";
import CreateTeam from "./pages/Team/createTeam";
import AllTeam from "./pages/Team/allTeam";

// ----------------------------------------------------------------------
export default function Router({ isAuthenticated, onLogin, onLogout }) {
  const routes = useRoutes([
    // Admin
    {
      path: "/admin",
      element:
        isAuthenticated === "Admin" ? (
          <DashboardLayout onLogout={onLogout} />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { path: "dashboard", element: <DashboardAppPage /> },
        { path: "sub-admin", element: <UserPage Role={"Sub Admin"} /> },
        {
          path: "relation-executive",
          element: <UserPage Role={"Relation Executive"} />,
        },
        { path: "super-admin", element: <UserPage Role={"Admin"} /> },
        {
          path: "field-officer",
          element: <UserPage Role={"Field Officer"} />,
        },
        {
          path: "regional-head",
          element: <UserPage Role={"Regional Head"} />,
        },
        {
          path: "area-sales-manager",
          element: <UserPage Role={"Area Sales Manager"} />,
        },
        { path: "plans", element: <PlansDetails /> },
        {
          path: "create-team",
          element: <CreateTeam />,
        },
        {
          path: "all-team",
          element: <AllTeam />,
        },
        { path: "customer-leads", element: <CustomerLeads /> },
        { path: "client-leads", element: <ClientLeads /> },
        { path: "customer-my-leads", element: <CustomerLeads Type="MyLead" /> },
        { path: "client-my-leads", element: <ClientLeads Type="MyLead" /> },
        { path: "allclients", element: <AllClients /> },
        { path: "allcustomers", element: <AllCustomers /> },
        { path: "notification", element: <Notification /> },
        { path: "settings", element: <Setting /> },
      ],
    },

    // Subadmin
    {
      path: "/sub-admin",
      element:
        isAuthenticated === "Sub Admin" ? (
          <DashboardLayout onLogout={onLogout} />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { path: "dashboard", element: <DashboardAppPage /> },
        {
          path: "relation-executive",
          element: <UserPage Role={"Relation Executive"} />,
        },
        {
          path: "field-officer",
          element: <UserPage Role={"Field Officer"} />,
        },
        {
          path: "regional-head",
          element: <UserPage Role={"Regional Head"} />,
        },
        {
          path: "area-sales-manager",
          element: <UserPage Role={"Area Sales Manager"} />,
        },
        {
          path: "create-team",
          element: <CreateTeam />,
        },
        {
          path: "all-team",
          element: <AllTeam />,
        },
        { path: "customer-leads", element: <CustomerLeads /> },
        { path: "client-leads", element: <ClientLeads /> },
        { path: "customer-my-leads", element: <CustomerLeads Type="MyLead" /> },
        { path: "client-my-leads", element: <ClientLeads Type="MyLead" /> },
        { path: "allclients", element: <AllClients /> },
        { path: "allcustomers", element: <AllCustomers /> },
        { path: "notification", element: <Notification /> },
      ],
    },

    //RegionalHead
    {
      path: "/regional-head",
      element:
        isAuthenticated === "Regional Head" ? (
          <DashboardLayout onLogout={onLogout} />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { path: "dashboard", element: <DashboardAppPage /> },
        { path: "customer-leads", element: <CustomerLeads /> },
        { path: "client-leads", element: <ClientLeads /> },
        { path: "customer-my-leads", element: <CustomerLeads Type="MyLead" /> },
        { path: "client-my-leads", element: <ClientLeads Type="MyLead" /> },
        { path: "allclients", element: <AllClients /> },
        { path: "allcustomers", element: <AllCustomers /> },
      ],
    },

    //Area sales manager
    {
      path: "/area-sales-manager",
      element:
        isAuthenticated === "Area Sales Manager" ? (
          <DashboardLayout onLogout={onLogout} />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { path: "dashboard", element: <DashboardAppPage /> },
        { path: "customer-leads", element: <CustomerLeads /> },
        { path: "client-leads", element: <ClientLeads /> },
        { path: "customer-my-leads", element: <CustomerLeads Type="MyLead" /> },
        { path: "client-my-leads", element: <ClientLeads Type="MyLead" /> },
        { path: "allclients", element: <AllClients /> },
        { path: "allcustomers", element: <AllCustomers /> },
      ],
    },

    // Relation Executive
    {
      path: "/relation-executive",
      element:
        isAuthenticated === "Relation Executive" ? (
          <DashboardLayout onLogout={onLogout} />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { path: "dashboard", element: <DashboardAppPage /> },
        { path: "customer-leads", element: <CustomerLeads /> },
        { path: "client-leads", element: <ClientLeads /> },
        { path: "customer-my-leads", element: <CustomerLeads Type="MyLead" /> },
        { path: "client-my-leads", element: <ClientLeads Type="MyLead" /> },
      ],
    },
    
    {
      path: "login",
      element: isAuthenticated ? (
        <Navigate to="/dashboard/app" />
      ) : (
        <LoginPage onLogin={onLogin} />
      ),
    },
    {
      element: <SimpleLayout />,
      children: [
        {
          element: (
            <Navigate to={isAuthenticated ? "/dashboard/app" : "/login"} />
          ),
          index: true,
        },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);
  return routes;
}
