import { Button, Card, Container, MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import LeadHistory from "../../components/Lead/LeadHistory";
const menuStyles = {
  marginTop: 10,
  maxHeight: 200,
};
function EditClientLead({ handleaddClientClose }) {
  const menuItem = [
    "Pending",
    "Wating for Executive",
    "Wating for TeleCaller",
    "Wating for Admin Approval",
  ];
  const [status, setStatus] = useState("Pending");
  const handleChange = (e) => {
    setStatus(e.target.value);
  };
  return (
    <Container>
      <Card className="p-3 h-calc">
        <div className="h-100 d-flex flex-column justify-content-between">
          <div className="d-flex flex-wrap">
            <div className="col-8 pe-3">
              <h5 className="fw-bold">Edit Client Lead</h5>

              <div className="d-flex flex-wrap justify-content-between">
                <div className="col-6 p-1">
                  <TextField
                    fullWidth
                    label="Name"
                    name="Name"
                    margin="normal"
                    size="small"
                  />
                </div>
                <div className="col-6 p-1">
                  <TextField
                    fullWidth
                    label="Phone No "
                    name="Phone No"
                    margin="normal"
                    size="small"
                  />
                </div>
                <div className="col-6 p-1">
                  <TextField
                    fullWidth
                    label="Mail "
                    name="Mail"
                    margin="normal"
                    size="small"
                  />
                </div>
                <div className="col-6 p-1">
                  <TextField
                    fullWidth
                    label="Address "
                    name="Address"
                    margin="normal"
                    size="small"
                  />
                </div>
                <div className="col-6 p-1">
                  <TextField
                    select
                    label="Status"
                    name="status"
                    value={status}
                    onChange={handleChange}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="mb-0 mt-3"
                    style={{ width: "100%" }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: menuStyles,
                        },
                      },
                    }}
                  >
                    {menuItem &&
                      menuItem.map((row, index) => (
                        <MenuItem key={index} value={row}>
                          {row}
                        </MenuItem>
                      ))}
                  </TextField>
                </div>
              </div>
            </div>
            <div className="col-4">
              <LeadHistory />
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="d-flex justify-content-end mt-3 col-8">
              <Button
                variant="contained"
                color="error"
                className="mx-3 btn-danger"
                onClick={handleaddClientClose}
              >
                Close
              </Button>
              <Button variant="contained" color="primary" className="btn-green">
                Submit
              </Button>
            </div>
            <div className="col-4"></div>
          </div>
        </div>
      </Card>
    </Container>
  );
}

export default EditClientLead;
