import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Iconify from "../iconify";
import { Button, IconButton } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { deleteData, getData, postFormData } from "../../webService/webService";
import { useDispatch } from "react-redux";
import API_URL from "../../utils/url";
import { showConfirmationDialog } from "../../utils/sweetalertUtils";

const Img = styled.img`
  border-radius: 10px;
  height: 150px;
  width: 150px;
  object-fit: cover;

  @media (max-width: 900px) {
    height: 100px;
    width: 100px;
  }
`;

const ImgDiv = styled.div`
  position: relative;
  border-radius: 10px;
  height: 150px;
  width: 150px;
  object-fit: cover;

  @media (max-width: 900px) {
    height: 100px;
    width: 100px;
  }
`;

const Icon = styled.span`
  position: absolute;
  background: red;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  color: white;
  align-items: center;
  right: -5%;
  top: -5%;
  justify-content: center;
`;

function BannerImages() {
  const accessToken = sessionStorage.getItem("Token");
  const dispatch = useDispatch();
  const [Data, setData] = useState(null);

  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);

  // Trigger the file input when the button is clicked
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Handle file selection, allowing only two files
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (selectedFiles.length + files.length > 1) {
      alert("You can only select a maximum of 1 images.");
      return;
    }
    setSelectedFiles([...selectedFiles, ...files]); // Add new files to state
  };

  // Handle removing a file from the list
  const handleFileRemove = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };

  // Handle form submission
  const handleSubmit = async () => {
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("bannerImages", selectedFiles[i]);
    }

    try {
      postFormData("banner", formData, accessToken, dispatch).then((res) => {
        console.log(res);
        setSelectedFiles([]);
        fetchdata();
      });
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  const DeleteImage = async (val) => {
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      deleteData(`banner/${val}`, accessToken, dispatch)
        .then((res) => {
          fetchdata();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const fetchdata = () => {
    getData("banners", null, accessToken)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchdata();
  }, []);

  console.log("Data", Data);
  return (
    <div>
      <ToastContainer />
      {Data?.length < 12 && (
        <div className="d-flex flex-warp justify-content-end align-content-center">
          <Button
            variant="contained"
            className="main-col-new"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleButtonClick}
          >
            Add Images
          </Button>
        </div>
      )}

      {/* Invisible file input */}
      <input
        type="file"
        ref={fileInputRef}
        accept="image/*"
        style={{ display: "none" }}
        // multiple
        // Allows multiple file selection
        onChange={handleFileChange}
      />

      {/* Display selected file names with delete option */}
      {selectedFiles.length > 0 && (
        <div>
          <h6 className="fw-bold">Selected File:</h6>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {selectedFiles.map((file, index) => (
              <li
                key={index}
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* Display image preview */}
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  style={{
                    width: "100px",
                    height: "100px",
                    marginRight: "10px",
                    objectFit: "cover",
                  }}
                />
                <span>{file.name}</span>
                {/* Remove button */}
                <IconButton
                  onClick={() => handleFileRemove(index)}
                  color="secondary"
                  aria-label="remove image"
                >
                  <Iconify icon="eva:trash-2-outline" />
                </IconButton>
              </li>
            ))}
          </ul>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={selectedFiles.length === 0}
          >
            Submit
          </Button>
        </div>
      )}

      <div className="d-flex justify-content-start align-content-center mt-3 w-100 overflow-x-scroll">
        {Data?.map((item, index) => (
          <div key={index} className="m-2">
            <ImgDiv>
              <Icon>
                <Iconify
                  icon="gg:close-o"
                  className="cursor-pointer"
                  onClick={() => DeleteImage(item._id)}
                />
              </Icon>
              <Img
                src={`https://api.aymrk.digitaltriumph.co.in/${item.bannerImages[0]}`}
                alt={item.bannerImages[0]}
              />
            </ImgDiv>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BannerImages;
