import {
  Button,
  Card,
  Container,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Iconify from "../../components/iconify";

function AddClientLead({ handleaddClientClose, action }) {
  const [formData, setFormData] = useState({
    name: "",
    zone: "",
    locality: "",
    pinCode: "",
    screenSize: "",
    noOfScreens: "",
    approxDailyEyeCachers: "",
    adType: "",
    propertyApartmentName: "",
    propertyPriceFrom: "",
    propertyPriceUpto: "",
    amenities: "",
    noOfBlocks: "",
    starVisibilityArea: "",
    captiveHouseholds: "",
    buildingAge: "",
    spotId: "",
    spotMediaId: "",
    buildingName: "",
    BusinessType: "",
    slotandLoop: "",
    mediaFormats: "",
    totalCostPerMonth: "",
    eventType: "",
    upcommingEventDate: "",
    totalCostPerEvent: "",
    officeBuildingName: "",
    organizationType: "",
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let tempErrors = {};
    let valid = true;

    if (!formData.name) {
      tempErrors.name = "name is required";
      valid = false;
    }

    if (!formData.zone) {
      tempErrors.zone = "zone is required";
      valid = false;
    }

    if (!formData.locality) {
      tempErrors.locality = "locality is required";
      valid = false;
    }

    if (!formData.pinCode) {
      tempErrors.pinCode = "pinCode is required";
      valid = false;
    }

    if (!formData.screenSize) {
      tempErrors.screenSize = "screenSize is required";
      valid = false;
    }

    if (!formData.noOfScreens) {
      tempErrors.noOfScreens = "noOfScreens is required";
      valid = false;
    }

    if (!formData.approxDailyEyeCachers) {
      tempErrors.approxDailyEyeCachers = "approxDailyEyeCachers is required";
      valid = false;
    }

    if (!formData.adType) {
      tempErrors.adType = "adType is required";
      valid = false;
    }

    if (formData.adType === "Corporate office") {
      if (!formData.officeBuildingName) {
        tempErrors.officeBuildingName = "officeBuildingName is required";
        valid = false;
      }

      if (!formData.organizationType) {
        tempErrors.organizationType = "organizationType is required";
        valid = false;
      }

      if (!formData.slotandLoop) {
        tempErrors.slotandLoop = "slotandLoop is required";
        valid = false;
      }

      if (!formData.mediaFormats) {
        tempErrors.mediaFormats = "mediaFormats is required";
        valid = false;
      }

      if (!formData.totalCostPerMonth) {
        tempErrors.totalCostPerMonth = "totalCostPerMonth is required";
        valid = false;
      }
    } else if (formData.adType === "Commercial") {
      if (!formData.buildingName) {
        tempErrors.buildingName = "buildingName is required";
        valid = false;
      }
      if (!formData.BusinessType) {
        tempErrors.BusinessType = "BusinessType is required";
        valid = false;
      }
      if (!formData.slotandLoop) {
        tempErrors.slotandLoop = "slotandLoop is required";
        valid = false;
      }

      if (!formData.mediaFormats) {
        tempErrors.mediaFormats = "mediaFormats is required";
        valid = false;
      }

      if (!formData.totalCostPerMonth) {
        tempErrors.totalCostPerMonth = "totalCostPerMonth is required";
        valid = false;
      }
    } else if (formData.adType === "Event Place") {
      if (!formData.buildingName) {
        tempErrors.buildingName = "buildingName is required";
        valid = false;
      }
      if (!formData.eventType) {
        tempErrors.eventType = "eventType is required";
        valid = false;
      }
      if (!formData.upcommingEventDate) {
        tempErrors.upcommingEventDate = "upcommingEventDate is required";
        valid = false;
      }
      if (!formData.slotandLoop) {
        tempErrors.slotandLoop = "slotandLoop is required";
        valid = false;
      }
      if (!formData.mediaFormats) {
        tempErrors.mediaFormats = "mediaFormats is required";
        valid = false;
      }
      if (!formData.totalCostPerEvent) {
        tempErrors.totalCostPerEvent = "totalCostPerEvent is required";
        valid = false;
      }
    } else {
      if (!formData.propertyApartmentName) {
        tempErrors.propertyApartmentName = "propertyApartmentName is required";
        valid = false;
      }
      if (!formData.propertyPriceFrom) {
        tempErrors.propertyPriceFrom = "propertyPriceFrom is required";
        valid = false;
      }
      if (!formData.propertyPriceUpto) {
        tempErrors.propertyPriceUpto = "propertyPriceUpto is required";
        valid = false;
      }
      if (!formData.amenities) {
        tempErrors.amenities = "amenities is required";
        valid = false;
      }
      if (!formData.noOfBlocks) {
        tempErrors.noOfBlocks = "noOfBlocks is required";
        valid = false;
      }
      if (!formData.starVisibilityArea) {
        tempErrors.starVisibilityArea = "starVisibilityArea is required";
        valid = false;
      }
      if (!formData.captiveHouseholds) {
        tempErrors.captiveHouseholds = "captiveHouseholds is required";
        valid = false;
      }
      if (!formData.buildingAge) {
        tempErrors.buildingAge = "buildingAge is required";
        valid = false;
      }
      if (!formData.spotId) {
        tempErrors.spotId = "spotId is required";
        valid = false;
      }
      if (!formData.spotMediaId) {
        tempErrors.spotMediaId = "spotMediaId is required";
        valid = false;
      }
    }

    setErrors(tempErrors);
    return valid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = () => {
    if (validateForm()) {
      console.log("submit");
    }
  };

  const menuStyles = {
    marginTop: 10,
    maxHeight: 200,
  };

  const menuItem = [
    "Residential",
    "Commercial",
    "Corporate office",
    "Event Place",
  ];

  const renderFields = () => {
    if (formData.adType === "Residential") {
      return (
        <div className="d-flex flex-wrap">
          <div className="col-3 px-1">
            <TextField
              fullWidth
              label="Property/Apartment Name *"
              name="propertyApartmentName"
              value={formData.propertyApartmentName}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.propertyApartmentName}
              helperText={errors.propertyApartmentName}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              type="number"
              label="Property Price From (in Cr) *"
              name="propertyPriceFrom"
              value={formData.propertyPriceFrom}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.propertyPriceFrom}
              helperText={errors.propertyPriceFrom}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              type="number"
              label="Property Price Upto (in Cr) *"
              name="propertyPriceUpto"
              value={formData.propertyPriceUpto}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.propertyPriceUpto}
              helperText={errors.propertyPriceUpto}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              label="Amenities *"
              name="amenities"
              value={formData.amenities}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.amenities}
              helperText={errors.amenities}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              type="number"
              label="No of Blocks *"
              name="noOfBlocks"
              value={formData.noOfBlocks}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.noOfBlocks}
              helperText={errors.noOfBlocks}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              label="Star Visibility Area *"
              name="starVisibilityArea"
              value={formData.starVisibilityArea}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.starVisibilityArea}
              helperText={errors.starVisibilityArea}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              type="number"
              label="Captive Households (No of flats) *"
              name="captiveHouseholds"
              value={formData.captiveHouseholds}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.captiveHouseholds}
              helperText={errors.captiveHouseholds}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              type="number"
              label="Building Age *"
              name="buildingAge"
              value={formData.buildingAge}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.buildingAge}
              helperText={errors.buildingAge}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              label="Spot Id *"
              type="number"
              name="spotId"
              value={formData.spotId}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.spotId}
              helperText={errors.spotId}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              type="number"
              label="Spot Media Id *"
              name="spotMediaId"
              value={formData.spotMediaId}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.spotMediaId}
              helperText={errors.spotMediaId}
            />
          </div>
        </div>
      );
    } else if (formData.adType === "Commercial") {
      return (
        <div className="d-flex flex-wrap">
          <div className="col-3 px-1">
            <TextField
              fullWidth
              label="Retail/Center/Shop/Mall Building Name *"
              name="buildingName"
              value={formData.buildingName}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.buildingName}
              helperText={errors.buildingName}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              label="Business Type *"
              name="BusinessType"
              value={formData.BusinessType}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.BusinessType}
              helperText={errors.BusinessType}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              label="Slot and Loop *"
              name="slotandLoop"
              value={formData.slotandLoop}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.slotandLoop}
              helperText={errors.slotandLoop}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              label="Media Formats *"
              name="mediaFormats"
              value={formData.mediaFormats}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.mediaFormats}
              helperText={errors.mediaFormats}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              type="number"
              label="Total Cost Per Month *"
              name="totalCostPerMonth"
              value={formData.totalCostPerMonth}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.totalCostPerMonth}
              helperText={errors.totalCostPerMonth}
            />
          </div>
        </div>
      );
    } else if (formData.adType === "Event Place") {
      return (
        <div className="d-flex flex-wrap">
          <div className="col-3 px-1">
            <TextField
              fullWidth
              label="Retail/Center/Shop/Mall Building Name *"
              name="buildingName"
              value={formData.buildingName}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.buildingName}
              helperText={errors.buildingName}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              label="Event Type *"
              name="eventType"
              value={formData.eventType}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.eventType}
              helperText={errors.eventType}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              label="Upcomming Event Date *"
              type="date"
              name="upcommingEventDate"
              InputLabelProps={{
                shrink: true,
              }}
              value={formData.upcommingEventDate}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.upcommingEventDate}
              helperText={errors.upcommingEventDate}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              label="Slot and Loop *"
              name="slotandLoop"
              value={formData.slotandLoop}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.slotandLoop}
              helperText={errors.slotandLoop}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              label="Media Formats *"
              name="mediaFormats"
              value={formData.mediaFormats}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.mediaFormats}
              helperText={errors.mediaFormats}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              type="number"
              label="Total Cost Per Event *"
              name="totalCostPerEvent"
              value={formData.totalCostPerEvent}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.totalCostPerEvent}
              helperText={errors.totalCostPerEvent}
            />
          </div>
        </div>
      );
    } else if (formData.adType === "Corporate office") {
      return (
        <div className="d-flex flex-wrap">
          <div className="col-3 px-1">
            <TextField
              fullWidth
              label="Office Building Name *"
              name="officeBuildingName"
              value={formData.officeBuildingName}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.officeBuildingName}
              helperText={errors.officeBuildingName}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              label="Organization Type *"
              name="organizationType"
              value={formData.organizationType}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.organizationType}
              helperText={errors.organizationType}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              label="Slot and Loop *"
              name="slotandLoop"
              value={formData.slotandLoop}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.slotandLoop}
              helperText={errors.slotandLoop}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              label="Media Formats *"
              name="mediaFormats"
              value={formData.mediaFormats}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.mediaFormats}
              helperText={errors.mediaFormats}
            />
          </div>
          <div className="col-3 px-1">
            <TextField
              fullWidth
              type="number"
              label="Total Cost Per Month *"
              name="totalCostPerMonth"
              value={formData.totalCostPerMonth}
              onChange={handleChange}
              margin="normal"
              autoComplete="off"
              size="small"
              error={!!errors.totalCostPerMonth}
              helperText={errors.totalCostPerMonth}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <Container>
        <Card className="p-3" style={{ height: "75vh" }}>
          <div className="h-100 d-flex flex-column justify-content-between">
            <div>
              <h5 className="fw-bold d-flex align-content-center">
                <Iconify
                  icon="mdi:arrow-back-circle"
                  className="cursor-pointer me-2"
                  onClick={handleaddClientClose}
                  style={{ height: "30px", width: "30px" }}
                />{" "}
                <span className="mt-1">{action} Client Lead</span>
              </h5>
              <div className="d-flex flex-wrap">
                <div className="col-3 px-1">
                  <TextField
                    fullWidth
                    label="Name *"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    margin="normal"
                    autoComplete="off"
                    size="small"
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </div>
                <div className="col-3 px-1">
                  <TextField
                    fullWidth
                    label="Zone *"
                    name="zone"
                    value={formData.zone}
                    onChange={handleChange}
                    margin="normal"
                    autoComplete="off"
                    size="small"
                    error={!!errors.zone}
                    helperText={errors.zone}
                  />
                </div>
                <div className="col-3 px-1">
                  <TextField
                    fullWidth
                    label="Locality *"
                    name="locality"
                    value={formData.locality}
                    onChange={handleChange}
                    margin="normal"
                    autoComplete="off"
                    size="small"
                    error={!!errors.locality}
                    helperText={errors.locality}
                  />
                </div>
                <div className="col-3 px-1">
                  <TextField
                    fullWidth
                    label="Pin Code *"
                    name="pinCode"
                    type="number"
                    value={formData.pinCode}
                    onChange={handleChange}
                    margin="normal"
                    autoComplete="off"
                    size="small"
                    error={!!errors.pinCode}
                    helperText={errors.pinCode}
                  />
                </div>
                <div className="col-3 px-1">
                  <TextField
                    fullWidth
                    label="Screen Size *"
                    type="number"
                    name="screenSize"
                    value={formData.screenSize}
                    onChange={handleChange}
                    margin="normal"
                    autoComplete="off"
                    size="small"
                    error={!!errors.screenSize}
                    helperText={errors.screenSize}
                  />
                </div>
                <div className="col-3 px-1">
                  <TextField
                    fullWidth
                    type="number"
                    label="No of Screens *"
                    name="noOfScreens"
                    value={formData.noOfScreens}
                    onChange={handleChange}
                    margin="normal"
                    autoComplete="off"
                    size="small"
                    error={!!errors.noOfScreens}
                    helperText={errors.noOfScreens}
                  />
                </div>
                <div className="col-3 px-1">
                  <TextField
                    fullWidth
                    label="Approx Daily Eye Cachers *"
                    name="approxDailyEyeCachers "
                    value={formData.approxDailyEyeCachers}
                    onChange={handleChange}
                    margin="normal"
                    autoComplete="off"
                    size="small"
                    error={!!errors.approxDailyEyeCachers}
                    helperText={errors.approxDailyEyeCachers}
                  />
                </div>
                <div className="col-3 px-1">
                  <TextField
                    select
                    label="Ad Type"
                    name="adType"
                    value={formData.adType}
                    onChange={handleChange}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="mb-0 mt-3"
                    style={{ width: "100%" }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: menuStyles,
                        },
                      },
                    }}
                    error={!!errors.adType}
                    helperText={errors.adType}
                  >
                    {menuItem &&
                      menuItem.map((row, index) => (
                        <MenuItem key={index} value={row}>
                          {row}
                        </MenuItem>
                      ))}
                  </TextField>
                </div>

                {renderFields()}
              </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="contained"
                color="error"
                className="mx-3 btn-danger"
                onClick={handleaddClientClose}
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="btn-green"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </Card>
      </Container>
    </>
  );
}

export default AddClientLead;
