import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Popover,
  Select,
  Slide,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import Iconify from "../../components/iconify";
import Scrollbar from "../../components/scrollbar/Scrollbar";
import AddCustomerLead from "./AddCustomerLead";
import EditCustomerLead from "./EditCustomerLead";
import SlideUpTransition from "../../utils/SlideUpTransition";
import Document from "../../Files/Addcustomer.xlsx";

function CustomerLeads({ Type }) {
  const Demo = [
    {
      name: "Selvam",
      phonenumber: "9090909090",
      email: "selvam@gmail.com",
      status: "Pending",
    },
    {
      name: "Raj",
      phonenumber: "9090909090",
      email: "Raj@gmail.com",
      status: "Waiting for teleceller",
    },
    {
      name: "Logesh",
      phonenumber: "9090909090",
      email: "Logesh@gmail.com",
      status: "Waiting for executive",
    },
    {
      name: "Karthick",
      phonenumber: "9090909090",
      email: "Karthick@gmail.com",
      status: "Waiting for adminapproval",
    },
    {
      name: "Demo",
      phonenumber: "9090909090",
      email: "Demo@gmail.com",
      status: "Pending",
    },
    {
      name: "Selvam",
      phonenumber: "9090909090",
      email: "selvam@gmail.com",
      status: "Pending",
    },
    {
      name: "Raj",
      phonenumber: "9090909090",
      email: "Raj@gmail.com",
      status: "Waiting for teleceller",
    },
    {
      name: "Logesh",
      phonenumber: "9090909090",
      email: "Logesh@gmail.com",
      status: "Waiting for executive",
    },
    {
      name: "Karthick",
      phonenumber: "9090909090",
      email: "Karthick@gmail.com",
      status: "Waiting for adminapproval",
    },
    {
      name: "Demo",
      phonenumber: "9090909090",
      email: "Demo@gmail.com",
      status: "Waiting for adminapproval",
    },
  ];
  const [Data, setData] = useState(Demo);
  //Add CustomerLeads
  const [addcustomeropen, setaddcustomeropen] = useState("");
  const [active, setActive] = useState();
  const [selectedFile, setSelectedFile] = useState(null);

  const [formData, setFormData] = useState({
    email: "",
    PhoneNumber: "",
    companyName: "",
    jobTitle: "",
    companyInformation: "",
    address: "",
    adType: "",
    demographicInformation: "",
    adTitle: "",
    adDuration: "",
    adFrequency: "",
    adSize: "",
    homePage: "",
    specifyCategory: "",
    articlePage: "",
    additionalRequirements: "",
    adDesign: "",
    animation: "",
    designServices: "",
    specialInstructions: "",
    sequencePricing: "",
    businessType: "",
    localityID: "",
    screenID: "",
    fromdate: "",
    todate: "",
    Details: "",
    Image: "",
  });
  const [errors, setErrors] = useState({});

  const handleaddcustomeropen = (data) => {
    setActive(data);
    setaddcustomeropen(data);
  };

  const handleaddcustomerClose = () => setaddcustomeropen("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = () => {
    console.log("submit");
  };

  const [openModal, setOpenModal] = useState(false);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedFile(null);
  };

  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const Relation_Executive = ["Arun", "Kannan", "Dinesh"];

  // Handle select change
  const handleCategoryChange = (id, newCategory) => {
    const updatedRows = Data.map((row) =>
      row.id === id ? { ...row, Relation_Executive: newCategory } : row
    );
    setData(updatedRows);
  };

  return (
    <>
      <Helmet>
        <title>Customer Leads</title>
      </Helmet>

      <ToastContainer />

      <Dialog
        open={openModal}
        TransitionComponent={SlideUpTransition}
        keepMounted
        onClose={handleCloseModal}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-paper": {
            backgroundImage: `url('https://t3.ftcdn.net/jpg/06/25/44/22/360_F_625442231_U5yVMRwJrG68u7WX41wLNSiSwlqE5QsQ.jpg')`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            color: "#fff",
          },
        }}
      >
        <DialogTitle className="text-white">Upload Excel File</DialogTitle>
        <DialogContent>
          <a
            href={Document}
            download="Addcustomer.xlsx"
            className="text-white"
            style={{ textDecoration: "none", color: "white" }}
          >
            Download Demo Excel <Iconify icon="flowbite:download-solid" />
          </a>

          <TextField
            fullWidth
            type="file"
            style={{ color: "white!important" }}
            className="text-white"
            inputProps={{ accept: ".xlsx, .xls" }}
            onChange={handleFileChange}
            margin="normal"
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            className="mx-3 btn-danger"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="btn-green"
            onClick={() => handleSubmit(selectedFile)} // Pass the selected file to handleSubmit
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {addcustomeropen === "Add" ? (
        <AddCustomerLead handleaddcustomerClose={handleaddcustomerClose} />
      ) : addcustomeropen === "Edit" ? (
        <EditCustomerLead handleaddcustomerClose={handleaddcustomerClose} />
      ) : (
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Customer Leads
            </Typography>
            {Type === "MyLead" ? (
              ""
            ) : (
              <div className="d-flex justify-content-between">
                <Button
                  variant="contained"
                  className="main-col-new me-4"
                  onClick={() => handleClickOpenModal()}
                >
                  Bulk Customer Lead
                </Button>
                <Button
                  variant="contained"
                  className="main-col-new"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                  onClick={() => handleaddcustomeropen("Add")}
                >
                  Add Customer Lead
                </Button>
              </div>
            )}
          </Stack>

          <Card>
            <Scrollbar>
              <TableContainer className="h-350">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Mail</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">
                        Assign Relation Executive
                      </TableCell>
                      <TableCell align="center">Assign Field Officer</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Data.map((data, index) => {
                      return (
                        <TableRow
                          key={index}
                          className="cursor-pointer table-hover"
                          onClick={() => handleaddcustomeropen("Edit")}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell>{data.name}</TableCell>
                          <TableCell>{data.phonenumber}</TableCell>
                          <TableCell>{data.email}</TableCell>
                          <TableCell align="center">
                            {data.status === "Pending" && (
                              <span class="badge cc-1"> {data.status}</span>
                            )}
                            {data.status === "Waiting for teleceller" && (
                              <span class="badge cc-2"> {data.status}</span>
                            )}
                            {data.status === "Waiting for executive" && (
                              <span class="badge cc-3"> {data.status}</span>
                            )}
                            {data.status === "Waiting for adminapproval" && (
                              <span class="badge cc-4"> {data.status}</span>
                            )}
                          </TableCell>
                          <TableCell>
                            <TextField
                              select
                              value={data.category}
                              onChange={(e) =>
                                handleCategoryChange(data.id, e.target.value)
                              }
                              variant="outlined"
                              fullWidth
                              size="small"
                            >
                              {Relation_Executive.map((category) => (
                                <MenuItem key={category} value={category}>
                                  {category}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <TextField
                              select
                              value={data.category}
                              onChange={(e) =>
                                handleCategoryChange(data.id, e.target.value)
                              }
                              variant="outlined"
                              fullWidth
                              size="small"
                            >
                              {Relation_Executive.map((category) => (
                                <MenuItem key={category} value={category}>
                                  {category}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <div className="d-flex flex-wrap justify-content-end m-3">
              <FormControl variant="outlined" size="small">
                <InputLabel id="limit-label">Limit</InputLabel>
                <Select
                  labelId="limit-label"
                  id="limit-select"
                  value={3}
                  // onChange={handleLimitChange}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </FormControl>
              <Button
              // onClick={handlePrevPage} disabled={currentPage === 1}
              >
                <Iconify icon={"grommet-icons:previous"} />
              </Button>
              <Button
              // onClick={handleNextPage}
              // disabled={currentPage === totalPages}
              >
                <Iconify icon={"grommet-icons:next"} />
              </Button>
            </div>
          </Card>
        </Container>
      )}
    </>
  );
}

export default CustomerLeads;
