import {
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Iconify from "../../components/iconify/Iconify";
import Scrollbar from "../../components/scrollbar/Scrollbar";
import AddCustomerLead from "../Leads/AddCustomerLead";

function AllCustomers() {
  const [addcustomeropen, setaddcustomeropen] = useState("");
  const [action, setAction] = useState();

  const handleaddcustomeropen = (data) => {
    setAction(data);
    setaddcustomeropen(data);
  };

  const handleaddcustomerClose = () => setaddcustomeropen("");

  function generateRandomData() {
    const companyNames = [
      "ManoTechnology",
      "Tech Solutions",
      "Innovative Labs",
      "Global Tech",
      "NextGen IT",
      "Prime Software",
      "Smart Systems",
      "FutureTech",
      "Advanced Networks",
      "Quantum Soft",
    ];
    const jobTitles = [
      "Software Engineer",
      "Data Analyst",
      "Project Manager",
      "UI/UX Designer",
      "System Administrator",
      "DevOps Engineer",
      "Database Administrator",
      "Technical Support",
      "Product Manager",
      "Security Analyst",
    ];
    const emailProviders = [
      "gmail.com",
      "yahoo.com",
      "outlook.com",
      "techcorp.com",
      "innovate.com",
    ];
    const plans = ["Gold", "Silver", "Platinum", "Bronze"];

    const data = [];

    for (let i = 1; i <= 20; i++) {
      const randomCompanyName =
        companyNames[Math.floor(Math.random() * companyNames.length)];
      const randomJobTitle =
        jobTitles[Math.floor(Math.random() * jobTitles.length)];
      const randomEmailProvider =
        emailProviders[Math.floor(Math.random() * emailProviders.length)];
      const randomPlan = plans[Math.floor(Math.random() * plans.length)];
      const randomLocalityID = Math.floor(Math.random() * 900 + 100).toString(); // Random number between 100 and 999
      const randomScreenID = Math.floor(Math.random() * 900 + 100).toString(); // Random number between 100 and 999
      const randomSubscriptionEndDate = `${Math.floor(
        Math.random() * 12 + 1
      )}-${Math.floor(Math.random() * 28 + 1)}-2024`; // Random date in 2024

      data.push({
        id: i.toString(),
        companyName: randomCompanyName,
        jobTitle: randomJobTitle,
        email: `${randomCompanyName.toLowerCase()}${i}@${randomEmailProvider}`,
        plan: randomPlan,
        localityID: randomLocalityID,
        screenID: randomScreenID,
        subscriptionEndDate: randomSubscriptionEndDate,
      });
    }

    return data;
  }

  const randomData = generateRandomData();
  console.log(randomData);

  return (
    <>
      {addcustomeropen === "Add" || addcustomeropen === "Edit" ? (
        <AddCustomerLead
          handleaddcustomerClose={handleaddcustomerClose}
          action={action}
        />
      ) : (
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              All Customers
            </Typography>

            <Button
              variant="contained"
              className="main-col-new"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => handleaddcustomeropen("Add")}
            >
              Add Customer
            </Button>
          </Stack>
          <Card>
            <Scrollbar>
              <TableContainer className="h-350">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Company Name</TableCell>
                      <TableCell>Job Title</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Plan</TableCell>
                      <TableCell>Locality ID</TableCell>
                      <TableCell>Screen ID</TableCell>
                      <TableCell>Subscription End Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {randomData.map((data, index) => {
                      return (
                        <TableRow
                          key={index}
                          className="cursor-pointer table-hover"
                          onClick={() => handleaddcustomeropen("Edit")}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell>{data.companyName}</TableCell>
                          <TableCell>{data.jobTitle}</TableCell>
                          <TableCell>{data.email}</TableCell>
                          <TableCell>{data.plan}</TableCell>
                          <TableCell>{data.localityID}</TableCell>
                          <TableCell>{data.screenID}</TableCell>
                          <TableCell>{data.subscriptionEndDate}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <div className="d-flex flex-wrap justify-content-end m-3">
              <FormControl variant="outlined" size="small">
                <InputLabel id="limit-label">Limit</InputLabel>
                <Select
                  labelId="limit-label"
                  id="limit-select"
                  value={3}
                  // onChange={handleLimitChange}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </FormControl>
              <Button
              // onClick={handlePrevPage} disabled={currentPage === 1}
              >
                <Iconify icon={"grommet-icons:previous"} />
              </Button>
              <Button
              // onClick={handleNextPage}
              // disabled={currentPage === totalPages}
              >
                <Iconify icon={"grommet-icons:next"} />
              </Button>
            </div>
          </Card>
        </Container>
      )}
    </>
  );
}

export default AllCustomers;
